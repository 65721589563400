import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BigpcService } from 'app/Services/bigpc.service';
import { DataService } from 'app/Services/data.service';
import { LocalstorgeService } from 'app/Services/localstorge.service';
import { SmallPcService } from 'app/Services/small-pc.service';
import { InfopopupComponent } from 'app/infopopup/infopopup.component';
import { SteuerhinweisPopupComponent } from 'app/popups/steuerhinweis-popup/steuerhinweis-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonServiceLatest } from 'app/Services/common.service';
import { BestellenPopupComponent } from 'app/popups/bestellen-popup/bestellen-popup.component';
import { environment } from '../../../environments/environment';
import { LanguageService } from 'app/Services/language.service';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { SafePipe } from '../../pipes/safe.pipe';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
@Component({
  selector: 'app-one',
  templateUrl: './one.component.html',
  styleUrls: ['./one.component.css'],
  providers: [SafePipe]
})
export class OneComponent implements OnInit {
  @ViewChild('mySVG') mySVG: ElementRef;

  //dynamic svg color adding
  svgs = [
    environment.assetsUrl + 'assets/star.svg',
    environment.assetsUrl + 'assets/infoSign.svg',
    environment.assetsUrl + 'assets/calculator1.svg',
    environment.assetsUrl + 'assets/svgs/voucherSVG.svg',
    environment.assetsUrl + 'assets/svgs/reload.svg',
    environment.assetsUrl + 'assets/svgs/priceAlaram.svg'
  ];
  tabErrorMsg: boolean = false;
  postalCodeErrorMsg: boolean = false;
  errorMsg: boolean = false;
  priceCalculationData: any = [];
  localSessionUpdatedData: any;
  administratedata;
  voucher = {
    valid: false,
    voucherCode: '',
    error: false,
    voucherId: '',
  };

  //paretnt to child data transmit
  @Input("products") products: any = {}
  @Input("productInfoForWebPC") productInfoForWebPC: any = {};

  // @Input("inLimitHouseHold") inLimitHouseHold: boolean;
  @Output() selectQualityEvent = new EventEmitter<any>();
  @Input() loader: boolean;
  maxDistance: any[];
  priceAlaramText: any;
  showPriceAlarm: any;
  pricealarmActivation: any;
  dynamicPcActive: boolean = false;
  preview: boolean = false;
  @Input() set _voucher(voucher) {
    this.voucher = voucher;
  };
  get _voucher() {
    return this.voucher
  };

  @Input() languageData: any;
  @Input() enableOrderBtn: boolean = false;
  @Input() deliveryDate;
  @Input() calculatedQualities: any;
  @Input() PCOptions: any[] = [];
  @Input() groupOrderMaxKM: Number;
  @Input() page: Number;
  @Input() alarmIndex: number;
  languageVar: any;
  @Input()
  set _priceCalculationData(priceCalculationData) {
    this.priceCalculationData = priceCalculationData;
    this.restoreSelectedQuality();
  };

  get _priceCalculationData() {
    return this.priceCalculationData;
  };

  @Input()
  set _localSessionUpdatedData(localSessionUpdatedData) {
    this.localSessionUpdatedData = localSessionUpdatedData;
    this.sessionData = localSessionUpdatedData;

    this.updateDefaultOptionData();
  }
  get _localSessionUpdatedData() {
    return this.localSessionUpdatedData
  }

  @Output() updateOptionsEvent = new EventEmitter<any>();
  @Output() BestellenEvent = new EventEmitter<any>();
  @Input() pcErrorHandling: any;
  @Output() priceDetailsPopupEvent = new EventEmitter<any>();
  @Output('showPreisweckerEvent') showPreisweckerEvent = new EventEmitter();
  label = '';
  priceFactor = '';
  symbol = '';
  deliveryStations = [];
  loadingStations = [];
  enableBtn: boolean;
  selectedIndex: any;
  selectedQuality: boolean;
  // @Input() co2CompensateValue: any;
  desiredDateSettings: any = {
    fromDate: '',
    toDate: '',
  };

  // svgIcons List
  starSvgIcon: any;
  voucherSVG: any;
  priceAlaram: any;
  star: any;
  starSvg: any;
  infoSvg: any;
  calculatrSvg: any;
  ratePayments: boolean = false;
  prepareForVisulization: any = [];
  ratePaymentObject: any;
  @Input() notSelectedOption: boolean;
  touchedVoucherInput: boolean = false;
  constructor(
    private fb: FormBuilder,
    public smallPCService: SmallPcService,
    public dataService: DataService,
    public bgPCService: BigpcService,
    public localStorageService: LocalstorgeService,
    private dialog: MatDialog,
    public httpClient: HttpClient,
    public sanitizer: DomSanitizer,
    public commonFunctions: CommonServiceLatest,
    public languageService: LanguageService,
    private SafePipe: SafePipe,
    private sso: ScrollStrategyOptions,
    private renderer: Renderer2,
    // private sso: ScrollStrategyOptions
  ) {
    // scrollTo(0, 0);
    if (window && typeof window.scrollTo === 'function') {
      window.scrollTo(0, 0);
    }
    let admindata = this.languageService.getLocalLanguageData();
    this.administratedata = admindata["administration"] || {};
    this.languageData = admindata['web-pc'] || {};
    this.languageService.onLanguageChange.subscribe((data: any) => {
      this.administratedata = data["administration"] || {};
      this.languageData = data['web-pc'] || {};
    });
    this.preview = localStorage.getItem('forPreview') == 'yes';
  };

  pcOptionForm = this.fb.group({
    product: ['', [Validators.required]],
    postalCode: ['', [Validators.required]],
    quantity: ['', [Validators.required]],
    voucher: ['', [Validators.required]],
    stations: ['', [Validators.required]],
    raten: ['', []],
    dynamicOptionsSets: this.fb.array([]),
  });

  url = '';
  sessionId = '';
  sessionData;
  localOptionSets = [];
  selectedProduct;
  postalCodeTitle = '';
  plzOptions = [];
  show: boolean = false;
  zahlungspans = [];

  ngOnInit(): void {
    this.getDynamicSVG(this.svgs);
    this.url = window.location.href;
    var params = new URLSearchParams(this.url);
    this.sessionId = decodeURIComponent(params.get("sid"));

    if (this.notSelectedOption) {
      this.updateDefaultOptionData();
    };

    //getting session data stored in localstorage
    this.smallPCService.getNewSessionData(this.sessionId).then(async (data: any) => {
      this.sessionData = data;
      this.localOptionSets = this.sessionData.localOptions;
      this.sessionData.quantity = this.commonFunctions.tradingSizeValidate(this.sessionData.quantity, this.productInfoForWebPC.tradingSize);
      this.pcOptionForm.get('quantity').setValue(this.sessionData.quantity);

      if (this.sessionData.postalCode.postalcode == "" || this.sessionData.postalCode.plzId == "") {
        this.tabErrorMsg = true;
      } else {
        this.pcOptionForm.get('postalCode').setValue(this.sessionData.postalCode.postalcode + ' ' + this.sessionData.postalCode.city + ' ' + this.sessionData.postalCode.suburb);
      };

      this.pcOptionForm.get('stations').setValue(parseInt(this.sessionData.stations));
      this.selectedProduct = this.sessionData.product;
      this.pcOptionForm.get('product').setValue(this.selectedProduct._id);
      this.pcOptionForm.get('voucher').setValue(this.sessionData.voucher?.voucherCode);
      this.voucher.valid = this.sessionData?.voucher?.valid ? this.sessionData?.voucher?.valid : '';
      this.localSessionUpdatedData = this.sessionData;
    });
    this.ratePaymentHideShow();
  };

  openPriceDetailsPopup() {
    let getSssionData: any = localStorage.getItem(this.sessionId);
    this.sessionData = JSON.parse(getSssionData);
    let selectedQuality = (this.sessionData['cart'] && this.sessionData['cart'][0].hasOwnProperty('dynamicProductId') && this.sessionData['cart'].length) ? this.sessionData['cart'] : [];
    if (selectedQuality) {
      this.priceDetails(selectedQuality[0]);
    } else {
      this.url = window.location.href; /*testcalc*/
      let url: string = this.url;
      let newUrl = url;
      // window.history.pushState("", document.title, url);
      if (newUrl.includes("stage=one")) {
        newUrl = newUrl.replace("&popup=pricedetails", "");
      };
      window.history.replaceState('', document.title, newUrl);
    };
  };

  webPcData: any;
  ratePaymentOptionSelected = false;


  ngOnChanges() {
    this.updateMyData(this.productInfoForWebPC);
    if (this.productInfoForWebPC && Object.keys(this.productInfoForWebPC).length) {
      // this.updateProductInfo(this.productInfoForWebPC);
    };

    this.label = this.productInfoForWebPC.unitsName;
    this.priceFactor = this.productInfoForWebPC.priceFactor;
    this.symbol = this.productInfoForWebPC.unitsSymbolForPc;
    this.languageVar = this.productInfoForWebPC?.language;
    this.priceAlaramText = this.productInfoForWebPC?.portalSettings?.alaramSettings?.priceAlarmText;
    if (environment.testSite_or_thirdPartySites && this.productInfoForWebPC?.portalSettings?.testMode) {
      this.showPriceAlarm = false;
    } else {
      this.showPriceAlarm = this.productInfoForWebPC?.portalSettings?.alaramSettings?.showPriceAlarm;
    };

    this.pricealarmActivation = this.productInfoForWebPC?.portalSettings?.alaramSettings?.status;
    this.deliveryStations = this.generateStations(this.productInfoForWebPC?.deliveryStationLimit);
    this.maxDistance = this.productInfoForWebPC.maxDistance;
    if (this.pcErrorHandling?.postalCodeHandleError) {
      this.postalCodeErrorMsg = true;
      this.plzOptions.length = 0;
      this.priceCalculationData = [];
      this.PCOptions = [];
      this.prepareForVisulization = [];
    } else {
      this.postalCodeErrorMsg = false;
    };
    this.updatedLocalStorage(this.localSessionUpdatedData);
    if (this.voucher.valid) {
      this.touchedVoucherInput = false;
    } else {
      this.smallPCService.getNewSessionData(this.sessionId).then(async (data: any) => {
        if (data?.voucher?.voucherCode) {
          this.touchedVoucherInput = true;
        } else {
          this.touchedVoucherInput = false;
        };
      });
    };
    this.pcOptionForm.get('stations').setValue(parseInt(this.sessionData?.stations));
    this.ratePaymentHideShow();
  };

  checkShowPriceAlarm(length) {
    if (length == 1) {
      return true;
    };
  };

  staticApeend(length, index) {
    if (index = 1 && length == 2 && this.alarmIndex == 2) {
      return true
    };
  };

  updatedLocalStorage(data) {
    this.localSessionUpdatedData = { ...data };
    this.ratePaymentOptionSelected = this.localSessionUpdatedData?.ratePaymentSettings?.status ? true : false;
    if (this.localSessionUpdatedData.ratePaymentSettings && this.localSessionUpdatedData.ratePaymentSettings.status) {
      this.pcOptionForm.get('raten').setValue(this.localSessionUpdatedData.ratePaymentSettings.zahlungRate)
      this.zahlungspans = this.localSessionUpdatedData.ratePaymentSettings['zahlungspans'];
    };
  };

  updateMyData(value) {
    this.webPcData = { ...value };
    this.updateDefaultOptionData();
  };

  // prepare form array for visualization
  getDynamicControls(): FormArray {
    return this.pcOptionForm.get('dynamicOptionsSets') as FormArray;
  };

  setDynamicOptions(data) {
    let dynamicOptions: FormArray;
    dynamicOptions = new FormArray([...data.map((item, i) => new FormGroup(
      {
        optionSetName: new FormControl(item.optionSetName, []),
        optionId: new FormControl(item.optionId, []),
        optionName: new FormControl(item.optionName, []),
        optionSetId: new FormControl(item.optionSetId, []),
        dynamicOptions: new FormControl(item.dynamicOptions, []),
        optionType: new FormControl(item.optionType, []),
      }
    ))] || []);
    this.pcOptionForm.setControl('dynamicOptionsSets', dynamicOptions);
  };

  updateDefaultOptionData() {
    this.url = window.location.href;
    var params = new URLSearchParams(this.url);
    this.sessionId = decodeURIComponent(params.get("sid"));

    if (this.PCOptions.length) {
      this.PCOptions = this.PCOptions.sort((a, b): any => {
        a.sortOrder < b.sortOrder ? -1 : 1;
      });

      let sessionData = localStorage.getItem(this.sessionId);

      sessionData = JSON.parse(sessionData);
      if (sessionData && this.sessionData?.quantity) {
        this.sessionData['quantity'] = this.commonFunctions.tradingSizeValidate(this.sessionData?.quantity, this.productInfoForWebPC?.tradingSize);
        this.pcOptionForm.get('quantity').setValue(this.sessionData.quantity);
        this.localStorageService.setSingleKeySessionData('quantity', this.sessionData['quantity']);
      };

      let localOptionsData = sessionData['localOptions'];
      this.prepareForVisulization = [];
      this.PCOptions.forEach((itemSet: any) => {
        this.selectedOptionValue(itemSet, localOptionsData);
      });
      this.setDynamicOptions(this.prepareForVisulization);
    };
  };


  selectedOptionValue(itemOptionSet, localOptions) {

    let localOptionMatched = false;
    itemOptionSet.dynamicOptions.map((option: any) => {

      localOptions.forEach((local: any) => {
        // console.log(local,3652)
        if (option.refCentralizedOptionId == local.optionId) {
          localOptionMatched = true;
          if ((local.name == "Ratenzahlung" || option.centralizedOptions.paymentOptionLink != 'none') && !this.sessionData.ratePaymentSettings.status) {
            console.log("case1");
            //fall back to top option if raypayment mode is set to off
            this.prepareForVisulization.push(
              {
                optionSetName: itemOptionSet.name,
                optionSetId: itemOptionSet.refCentralizedOptionSetId,
                optionId: option?.refCentralizedOptionId || itemOptionSet.dynamicOptions[0].refCentralizedOptionId,
                optionName: option?.centralizedOptions?.name || itemOptionSet.dynamicOptions[0].centralizedOptions.name,
                dynamicOptions: itemOptionSet.dynamicOptions,
                optionType: itemOptionSet.optionType
              }
            );
          } else {
            //  dynamic options preparing for form
            this.prepareForVisulization.push(
              {
                optionSetName: itemOptionSet.name,
                optionSetId: itemOptionSet.refCentralizedOptionSetId,
                optionId: option.refCentralizedOptionId,
                optionName: option.centralizedOptions.name,
                dynamicOptions: itemOptionSet.dynamicOptions,
                optionType: itemOptionSet.optionType
              }
            );
          };

          // else if(option.centralizedOptions.paymentOptionLink != 'none' && local.name !="Ratenzahlung" ){
          //   console.log("case2",383);
          //   this.prepareForVisulization.push(
          //     {
          //       optionSetName: itemOptionSet.name,
          //       optionSetId: itemOptionSet.refCentralizedOptionSetId,
          //       optionId: itemOptionSet.dynamicOptions[0].refCentralizedOptionId,
          //       optionName: itemOptionSet.dynamicOptions[0].centralizedOptions.name,
          //       dynamicOptions: itemOptionSet.dynamicOptions,
          //       optionType: itemOptionSet.optionType
          //     }
          //   )
          // }
        }
      });
    });
    if (!localOptionMatched) {
      this.prepareForVisulization.push(
        {
          optionSetName: itemOptionSet.name,
          optionSetId: itemOptionSet.refCentralizedOptionSetId,
          optionId: itemOptionSet.dynamicOptions[0]?.refCentralizedOptionId,
          optionName: itemOptionSet.dynamicOptions[0]?.centralizedOptions.name,
          dynamicOptions: itemOptionSet.dynamicOptions,
          optionType: itemOptionSet.optionType
        }
      );
      this.updateOptionsEvent.emit({ event: itemOptionSet.dynamicOptions[0], name: 'DYNAMICOPTIONS', optionType: itemOptionSet.optionType });
    };
  };


  updatepcOptions(options) {
    this.PCOptions && this.ratePaymentHideShow();
  };

  // updateProductInfo(data) {
  //   this.productInfoForWebPC = { ...data };
  //   if (this.productInfoForWebPC.webCalculator) {
  //     this.dynamicPcActive = true;
  //   } else {
  //     this.dynamicPcActive = false;
  //   };
  // };

  generateStations(count) {
    count = count == 0 ? 1 : count;
    let stations = [];
    Array.from({ length: count }, (_, idx) => {
      stations.push({ "value": parseInt(`${++idx}`) });
    });
    this.deliveryStations = stations;
    return stations;
  };

  createNewForm(data) {
    this.pcOptionForm.setControl('dynamicOptionsSets', this.fb.array([]));
    data.forEach((item, index) => {
      (this.pcOptionForm.get('dynamicOptionsSets') as FormArray).push(this.createField(item));
    });
  };

  createField(item): FormGroup {
    return this.fb.group({
      [item.name]: [item, Validators.required],
    });
  };

  headingNameFetch(t) {
    let key = Object.keys(t.value);
    return key;
  };

  getOptionType(item) {
    let key = Object.keys(item.value);
    let options = item.value[`${key}`];
    return options.optionType;
  };

  optionData(item) {
    let key = Object.keys(item.value);
    let options = item.value[`${key}`];
    return options['dynamicOptions'];
  };
  productShift = false;
  updateoptions(event, name) {
    if (name == 'PRODUCT') {
      event = this.pcOptionForm.get('product').value;
      this.productShift = true;
      if (this.sessionData?.hasOwnProperty('isDynamicOptionEdited')) {
        this.sessionData.isDynamicOptionEdited = false;
        this.localStorageService.setSingleKeySessionData('isDynamicOptionEdited', false);
      };
    } else if (name == 'STATIONS') {
      event = this.pcOptionForm.get('stations').value;
      let isValid = this.validateMeng();
      if (isValid && this.pcOptionForm.controls['stations'].value > 1) {
        this.priceCalculationData = [];
        this.localStorageService.setSingleKeySessionData('stations', event);
        return;
      };
    };
    this.updateOptionsEvent.emit({ event, name });
  };

  ngUnsubscribe: Subject<void> = new Subject<void>();
  // we need to empty only when new value is entered
  updateoptionsQuantity(event, name) {
    const quantityControl = this.pcOptionForm.get('quantity');
    quantityControl.valueChanges.pipe(distinctUntilChanged(), debounceTime(800), takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      if (name == "QUANTITY") {
        event = this.commonFunctions.tradingSizeValidate(this.pcOptionForm.get('quantity').value, this.productInfoForWebPC.tradingSize);
        if (!isNaN(event)) {
          this.pcOptionForm.get('quantity').setValue(event);
        }
      } else {
        event = event?.target?.value;
      };
      if (this.sessionData.quantity !== event) {
        this.updateOptionsEvent.emit({ event, name });
        this.sessionData.quantity = event;
      }
    }, err => {
      console.log(err);
    });
  };

  dynamicOptionUpdate(event, name, selectedItem) {
    if (event == 'WUNSTERMIN') {
      let optionType = selectedItem.optionType;
      this.updateOptionsEvent.emit({ event, name, optionType });
    } else {
      let optionData = selectedItem.dynamicOptions;
      let optionType = selectedItem.optionType;
      let eventObj = event;
      if (name == "RATEN") {
        this.updateOptionsEvent.emit({ event, name, optionType });
        return
      };
      name = name.trim();
      event = optionData.find((it) => it.refCentralizedOptionId == eventObj);
      if (name == "DYNAMICOPTIONS" && !this.sessionData.isDynamicOptionEdited) {
        this.sessionData.isDynamicOptionEdited = true;
        this.localStorageService.setSingleKeySessionData('isDynamicOptionEdited', true)
      };
      if (name == 'DYNAMICOPTIONS' && event.optionType == 12 && (event['centralizedOptions']['name'].trim() !== "Ratenzahlung" || event['centralizedOptions']['paymentOptionLink'] == "none")) {
        this.ratePaymentOptionSelected = true;
        this.localSessionUpdatedData.ratePaymentSettings['status'] = false;
        this.localSessionUpdatedData.ratePaymentSettings['zahlungspans'] = [];
        this.localSessionUpdatedData.ratePaymentSettings['zahlungRate'] = '';
        this.localStorageService.setSingleKeySessionData("ratePaymentSettings", {
          status: false,
          zahlungspans: [],
          zahlungRate: ''
        });
        this.sessionData['ratePaymentSettings'] = {
          status: false,
          zahlungspans: [],
          zahlungRate: ''
        };
      }
      if (name == "DYNAMICOPTIONS" && event.optionType == 8) {
        if (event['centralizedOptions']['name'].trim() != "Ratenzahlung") {
          // this.localSessionUpdatedData.ratePaymentSettings['status'] = false;
          // this.localSessionUpdatedData.ratePaymentSettings['zahlungspans'] = [];
          // this.localSessionUpdatedData.ratePaymentSettings['zahlungRate'] = '';
          // this.localStorageService.setSingleKeySessionData("ratePaymentSettings", {
          //   status: false,
          //   zahlungspans: [],
          //   zahlungRate: ''
          // });
          // this.sessionData['ratePaymentSettings'] = {
          //   status: false,
          //   zahlungspans: [],
          //   zahlungRate: ''
          // };
        } else {

          // this.localSessionUpdatedData.ratePaymentSettings['status'] = true;
          this.zahlungspans = this.localStorageService.getSessionData(this.sessionId)['ratePaymentSettings']['zahlungspans']
        };
      };
      this.updateOptionsEvent.emit({ event, name, optionType });
    };
  };

  get Control() {
    return this.pcOptionForm.controls;
  };

  minMaxValidator(webPcData) {
    let min: number = webPcData.minAmount;
    let max: number = webPcData.maxAmount;
    let inputValue = this.Control.quantity.value;
    if (inputValue <= max && inputValue >= min) {
      return false;
    };
    return true;
  };

  showPreiswecker() {
    this.showPreisweckerEvent.emit(true);
  };

  validate(e) {
    var regex = new RegExp("[a-zA-Z0-9äöüÄÖÜß ]");
    var key = e.keyCode || e.which;
    key = String.fromCharCode(key);
    if (!regex.test(key)) {
      e.returnValue = false;
      if (e.preventDefault) {
        e.preventDefault();
      };
    };
  };

  selectQuality(selected, index: any, data, selectedProduct) {
    let cartInfo = [];
    if (data) {
      cartInfo.push(data);
    };

    this.localStorageService.setSingleKeySessionData('cart', cartInfo);
    this.selectedQuality = true;
    if (selectedProduct == 'on') {
      this.enableBtn = true;
      this.selectedIndex = index;
    } else {
      this.enableBtn = false
    };

    this.priceCalculationData.map((pcQuality, i) => {
      if (i == index) {
        pcQuality.selected = true;
        this.selectQualityEvent.emit(pcQuality);
        // console.log(pcQuality, 591);
        if (pcQuality?.qualities.co2Compensation?.type == 'Dauerhaft') {
          let co2CompensateArray = [];
          co2CompensateArray.push(pcQuality.qualities._id);
          this.localStorageService.setSingleKeySessionData('co2Compensation', co2CompensateArray);
        } else {
          this.localStorageService.setSingleKeySessionData('co2Compensation', []);
        };
      } else {
        pcQuality.selected = false;
      };
    });
  };

  specialtextpopup1(text) {
    let dialogRef = this.dialog.open(InfopopupComponent, {
      panelClass: "my-full-screen-dialog",
      width: "50vw",
    });
    // dialogRef.componentInstance.colors = this.colors;
    dialogRef.componentInstance.infotext = text;
  };

  steuerhinweisPopup(text) {
    let dialogRef = this.dialog.open(
      SteuerhinweisPopupComponent,
      {
        panelClass: "my-full-screen-dialog",
        width: "500px",
        // data: { ...this.priceCalculationData },
      });
    // dialogRef.componentInstance.colors = this.colors;
    dialogRef.componentInstance.infotext = text;
  };

  co2Compensate(qualityId) {
    let name = "CO2COMPENSATE";
    let event = qualityId
    this.updateOptionsEvent.emit({ event, name });
  };

  onKeyy(e) {
    if (e.key == "Tab") {
      this.tabErrorMsg = this.sessionData.postalCode.plzId ? false : true
    } else if (e.key == "Backspace") {
      this.sessionData.postalCode.plzId = undefined;
      if (this.sessionData.postalCode.plzId == undefined) {
        this.tabErrorMsg = true;
      };
    };
  };

  check(id) {
    return (this.localSessionUpdatedData.co2Compensation && this.localSessionUpdatedData.co2Compensation.includes(id)) ? true : false
  };

  getPostalCode(e) {
    if (e.length >= 4) {
      let sessionId = this.localStorageService.getSessionId();
      let getSssionData: any = localStorage.getItem(sessionId);
      this.sessionData = JSON.parse(getSssionData);
      let objectname = {
        event: e,
        dynamicProductId: this.sessionData.product._id
      };
      this.bgPCService.postalCode(objectname).subscribe((data: any) => {
        if (data.success) {
          this.plzOptions = data.data;

          let Options = data.data
          this.plzOptions = Options.sort((a, b) => {
            if (`${a.country_code} ${a.place_name} ${a.suburb}` < `${b.country_code} ${b.place_name} ${b.suburb}`) { return -1; }
            if (`${a.country_code} ${a.place_name} ${a.suburb}` > `${b.country_code} ${b.place_name} ${b.suburb}`) { return 1; }
          });
          this.postalCodeErrorMsg = false;
          let postelAddress = this.sessionData.postalCode.postalcode + ' ' + this.sessionData.postalCode.city + ' ' + this.sessionData.postalCode.suburb;
          let findPostalCode = this.plzOptions.find(item => (item.ortPlz).trim() == postelAddress.trim());
          if (typeof findPostalCode != 'object') {
            this.postalCodeErrorMsg = true
          };
        } else {
          this.plzOptions.length = 0;
          this.postalCodeErrorMsg = true;
          this.priceCalculationData = [];
        };
      });
    } else {
      this.plzOptions = [];
      this.postalCodeErrorMsg = false;
    };
  };

  matItemSelect(event) {
    this.tabErrorMsg = false;
    event.title = event.country_code + " " + event.place_name + " " + event.suburb;
    let postalCode = {
      postalcode: event.country_code,
      plzId: event.plzId,
      city: event.place_name,
      suburb: event.suburb,
      latitude: event.latitude,
      longitude: event.longitude,
    };

    event = postalCode;
    let name = 'POSTALCODE';
    this.updateOptionsEvent.emit({ event, name });
  };

  priceDetails(quality) {
    this.priceDetailsPopupEvent.emit(quality);
  };

  voucherfun(event) {
    let name = "VOUCHER";
    event = event.target.value;
    this.updateOptionsEvent.emit({ event, name });
  };


  selectRateOption(event) {
    if (event) {
      console.log("case1");
      let name = 'DYNAMICOPTIONS';
      event = this.ratePaymentObject;
      let options = this.PCOptions;
      let paymentOptions = options.find(ref => ref['optionType'] == 12);
      console.log(paymentOptions, 730)

      let emiObj = paymentOptions['dynamicOptions'] && paymentOptions['dynamicOptions'].find(it => (it.centralizedOptions.name == "Ratenzahlung" || it.centralizedOptions.paymentOptionLink != "none"));

      this.getDynamicControls().controls.forEach((i, index) => {
        let currentData = this.getDynamicControls().controls[index].value;

        let name: string = currentData.optionSetName;
        if (name.match(/Zahlungsart/ig)) {
          console.log("currentData", currentData);
          console.log("emiObj", emiObj);
          this.getDynamicControls().controls[index].patchValue(
            {
              dynamicOptions: currentData.dynamicOptions,
              dynamicPcOptionsSetId: emiObj._id,
              lable: currentData.label,
              name: emiObj.name,
              optionId: emiObj.refCentralizedOptionId,
              optionSetId: currentData.optionSetId,
              sort: 6
            }
          )
        }
      })
      this.updateOptionsEvent.emit({ event, name });
    } else {
      console.log("case2");
      //if unselected is not selected
      let name = 'DYNAMICOPTIONS';
      event = this.ratePaymentObject;
      let options = this.PCOptions;
      let paymentOption = options.find(ref => ref['optionType'] == 12);
      let filterRatenzalung = paymentOption['dynamicOptions'] && paymentOption['dynamicOptions'].filter(it => it.name != "Ratenzahlung");
      let emiObj = filterRatenzalung && filterRatenzalung[0];
      this.getDynamicControls().controls.forEach((i, index) => {
        let currentData = this.getDynamicControls().controls[index].value;
        let name: string = currentData.optionSetName;
        if (name.match(/Zahlungsart/ig)) {
          this.getDynamicControls().controls[index].patchValue(
            {
              dynamicOptions: currentData.dynamicOptions,
              dynamicPcOptionsSetId: emiObj._id,
              lable: currentData.label,
              name: emiObj.name,
              optionId: emiObj.refCentralizedOptionId,
              optionSetId: currentData.optionSetId,
              sort: 6
            }
          )
          event = emiObj
        }
      })
      this.updateOptionsEvent.emit({ event, name });
    }
  };

  restoreSelectedQuality() {
    console.log("restoreSelectedQuality", this.priceCalculationData);
    if (this.priceCalculationData && this.priceCalculationData.length) {
      let selectedProduct = this.localStorageService.getSingleSessionData('cart');
      console.log(selectedProduct, 796)

      if (selectedProduct && selectedProduct.length > 0 && 'qualities' in selectedProduct && !selectedProduct[0]?.qualities._id) {
        console.log("case1");
        this.priceCalculationData.map((pcQuality, i) => {
          if (pcQuality.qualities.webOption) {
            this.updateSelectedQuality(pcQuality, i);
          } else {
            pcQuality.selected = false;
            if (this.productShift) {
              this.productShift = false;
              this.selectedQuality = false;
              this.selectedIndex = null;
            }
          };
        });
      } else {
        console.log("case2");
        this.priceCalculationData.map((pcQuality, i) => {
          if (pcQuality?.qualities?._id == selectedProduct[0]?.qualities?._id) {
            this.updateSelectedQuality(pcQuality, i);
          } else {
            pcQuality.selected = false;
            // this.selectedQuality = false;
            // this.selectedIndex = null;
            if (this.productShift) {
              this.productShift = false;
              this.selectedQuality = false;
              this.selectedIndex = null;
            }
          };
        });

      };
    };
  };

  updateSelectedQuality(pcQuality, i) {
    this.localStorageService.setSingleKeySessionData('cart', [pcQuality]);
    if (this.url.includes("popup=pricedetails")) {
      this.openPriceDetailsPopup();
    };
    pcQuality.selected = true;
    this.selectedIndex = i;
    this.selectedQuality = true;


  };

  bestellen() {
    // to move next stage this is the final button
    this.pcOptionForm.value.postalCode = this.SafePipe.transform(this.pcOptionForm.value.postalCode, 'html')
    this.pcOptionForm.value.stations = this.SafePipe.transform(this.pcOptionForm.value.stations, 'html')
    this.pcOptionForm.value.voucher = this.SafePipe.transform(this.pcOptionForm.value.voucher, 'html')
    let selectedQualityHere = this.sessionData['cart'];
    let dynamicOptionEdited = this.localStorageService.getSingleSessionData('isDynamicOptionEdited');
    if (!this.productInfoForWebPC.standardOptionPopup || dynamicOptionEdited) {
      this.BestellenEvent.emit(selectedQualityHere);
      return;
    };
    let popupData = [
      {
        name: 'Abladestellen',
        selectedname: this.pcOptionForm.value.stations
      },
    ];
    this.pcOptionForm.value.dynamicOptionsSets.map(each => popupData.push({ name: each.optionSetName, selectedname: each.optionName }))
    const dialogRef = this.dialog.open(
      BestellenPopupComponent,
      {
        panelClass: 'my-full-screen-dialog',
        width: '500px',
        data: popupData,
        scrollStrategy: this.sso.noop()
      });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res === 'Bestellen') {
        if (window && typeof window.scrollTo === 'function') {
          window.scrollTo(0, 0);
        };
        this.BestellenEvent.emit(selectedQualityHere);
      };
    }, (err) => console.error(err))
  };

  voucherValidity() {
    this.voucher['voucherCode'] = this.pcOptionForm.get('voucher').value;
    let event = this.voucher['voucherCode'];
    let name = 'VOUCHER';
    this.updateOptionsEvent.emit({ event, name }); // this.voucher['valid'] = false;
  };

  getDynamicSVG(svgs) {
    svgs.forEach((svgItem) => {
      let item = svgItem;
      let first = item.split('.svg')[0];
      let iconNameArray = first.split('/');
      let iconName = iconNameArray.pop();
      this.httpClient.get(item, { responseType: 'text' }).subscribe((logo) => {
        logo = logo.replace(/#333/ig, 'var(--primaryColour)'); //fill
        logo = logo.replace(/#111/ig, 'var(--highlightColour)'); //storke
        this[iconName] = this.sanitizer.bypassSecurityTrustHtml(logo);
      });
    });
  };
  fillColor = 'red';
  changeColor() {
    const svgElements = this.mySVG.nativeElement.querySelectorAll('path'); // Adjust selector for your SVG's elements
    for (let element of svgElements) {
      this.renderer.setStyle(element, 'fill', 'green');
    }
  }
  ratePaymentHideShow() {
    // alert("rate payment hide and show")
    let options = this.PCOptions;
    this.ratePayments = false;
    options.length && options.forEach((item) => {
      if (item['optionType'] == 12) {
        let dynamicOptions = item['dynamicOptions'];
        for (let i = 0; i < dynamicOptions.length; i++) {
          if (dynamicOptions[i]['centralizedOptions']['name'] == "Ratenzahlung" || dynamicOptions[i]['centralizedOptions']['paymentOptionLink'] != "none") {
            this.ratePayments = true;
            this.ratePaymentObject = dynamicOptions[i];
            break;
          };
        };
      };
    });
  };

  isLitterValidation() {
    if (!this.productInfoForWebPC.activatePc) {
      return false;
    };
    let min: number = this.productInfoForWebPC.minAmount;
    let max: number = this.productInfoForWebPC.maxAmount;
    let inputValue = this.pcOptionForm.get('quantity').value;
    if (inputValue <= max && inputValue >= min) {
      return false;
    };
    return true;
  };

  inLimitHouseHold = false;

  validateMeng() {
    if (this.pcOptionForm.controls['stations'].value < 2) {
      this.inLimitHouseHold = false;
      return false;
    };
    let min: number = this.productInfoForWebPC.minAmount;
    let minMumValue = min * this.pcOptionForm.controls['stations'].value;
    let liters = Number(this.pcOptionForm.controls['quantity'].value);
    if (liters < minMumValue && !this.isLitterValidation()) {
      this.inLimitHouseHold = true;
      return true;
    } else {
      this.inLimitHouseHold = false;
      return false;
    };
  };
};
