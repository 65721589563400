<div>
    <h2 class="header1" style="text-align: center;margin-top: -5px;"> Adresse auswählen </h2>
    <div [ngClass]="{'cards1': data.address.length === 1, 'cards': data.address.length > 1}">
      <div *ngFor="let addresen of data?.address; let i = index" class="cardssss" (click)="natigateSetup(i)" style="border: 1px solid #ddd;border-radius: 5px;padding: 10px;">
        <div>
          <b>
            {{addresen?.currentTitle}}
            {{addresen?.firstName}}
            {{addresen?.lastName}}
          </b>
        </div>
        <div>
          {{addresen?.streetName}}
          <span *ngIf="addresen?.houseNo">
            {{addresen?.houseNo}}
          </span>
          <div>
            {{addresen?.zipCode}}
            {{addresen?.city}}
          </div>
        </div>
      </div>
    </div>
  </div>