import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MeinepopupComponent } from 'app/popups/meinepopup/meinepopup.component';
import { LanguageService } from '../Services/language.service';
import * as Highcharts from 'highcharts';
import { AdminService } from 'app/Services/admin.service';
import { SmallPcService } from 'app/Services/small-pc.service';
// import { MeineHeizungSetupComponent } from 'app/popups/meine-heizung-setup/meine-heizung-setup.component';
import { MeineHeizungSetupComponent } from '../popups/meine-heizung-setup/meine-heizung-setup.component';
import { DomSanitizer } from '@angular/platform-browser';
import HighchartsMore from 'highcharts/highcharts-more';
// import { CosumptionComponent } from 'app/popups/cosumption/cosumption.component';
import { CosumptionComponent } from '../popups/cosumption/cosumption.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InventoyTrackingService } from 'app/Services/inventoy-tracking.service';
HighchartsMore(Highcharts);
import * as Highchartstock from 'highcharts/highstock';
import { AddresspopupComponent } from 'app/addresspopup/addresspopup.component';

import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-create-inventory-tracking',
  templateUrl: './create-inventory-tracking.component.html',
  styleUrls: ['./create-inventory-tracking.component.css']
})
export class CreateInventoryTrackingComponent implements OnInit {
  currentSetupStage = 4;
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any = {};
  address: any;

  @Output() naivateToAdresses = new EventEmitter();
  addHeatSetup: any;
  currentDate: Date;
  showAdresses: boolean = false;
  adressenpopup: boolean = false;
  addresss: any;
  heatingSetup: boolean;
  heatingOverview: boolean;
  addNewEntry: boolean = false;
  @Input() fromAdreses;
  selectAdress: any;
  select: boolean = false;
  setupView: boolean = false;

  // realverbrauch: any = [
  //   {
  //     year: 2018,
  //     total: 8650
  //   }, {
  //     year: 2019,
  //     total: 8710
  //   }, {
  //     year: 2020,
  //     total: 8320
  //   }, {
  //     year: 2021,
  //     total: 8390
  //   }, {
  //     year: 2022,
  //     total: 8470
  //   }, {
  //     year: 2023,
  //     total: 8473
  //   }
  // ];
  realverbrauch: any = [];
  realConsumptionYears = ['2018', '2019', '2020', '2021', '2022', '2023'];
  realConsumptionData = [8650, 8710, 8320, 8390, 8470, 8473];
  lineGraphData = [];
  lineGaraphObj = {};
  showFullScreenLoading: boolean = false;
  svgs = [
    environment.assetsUrl + 'assets/inventory/befullung.svg',
    environment.assetsUrl + 'assets/inventory/Bestandskontrolle.svg',
    environment.assetsUrl + 'assets/inventory/preisrechner.svg',
    environment.assetsUrl + 'assets/inventory/Info.svg',
    environment.assetsUrl + 'assets/inventory/Heaterrr.svg',
  ];
  constructor(
    public dialog: MatDialog,
    public adminService: AdminService,
    private sampleService: SmallPcService,
    public sanitizer: DomSanitizer,
    public languageService: LanguageService,
    private fb: FormBuilder,
    private inventoryService: InventoyTrackingService,
    public httpClient: HttpClient,
  ) {
    this.currentDate = new Date();
    this.inventoryService.notifyHeatingRecordObservable$.pipe(
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
    ).subscribe((res) => {
      if (res == 'update') {
        this.getAddresses().then((data): any => {
          console.log(data, 564554);
        });
      }
    });
  }


  ngOnInit(): void {


    let langData = this.languageService.getLocalLanguageData();
    this.languageData = langData["kundeportal"] || {};
    console.log(this.languageData)
    this.languageService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data["kundeportal"] || {};
    });
    if (this.fromAdreses?.show) {
      this.meinepopupopen();
      this.fromAdreses = { show: true, address: this.fromAdreses.address }
      this.heatingOverview = false
    } else {
      this.getAddresses().then(res => {
        console.log(res, 9334);
        console.log("address received", 78)
      });
    };
    this.getDynamicSVG(this.svgs);
  }

  getDynamicSVG(svgs) {
    svgs.forEach((svgItem) => {
      let item = svgItem;
      let first = item.split('.svg')[0];
      let iconNameArray = first.split('/');
      let iconName = iconNameArray.pop();
      this.httpClient.get(item, { responseType: 'text' }).subscribe((logo) => {
        logo = logo.replace(/#333/ig, 'var(--primaryColour)'); //fill
        logo = logo.replace(/#111/ig, 'var(--highlightColour)'); //storke
        logo = logo.replace(/#444/ig, 'var(--colorShade1)'); //shade1
        logo = logo.replace(/#555/ig, 'var(--colorShade2)'); //shade2
        this[iconName] = this.sanitizer.bypassSecurityTrustHtml(logo);
      })
    });
  };
  meinepopupopen() {
    this.inventoryService.fetchInventoryProducts({}).subscribe((data: any) => {
      let myHeatNoProducts = data.data.find(p => p.type == 'products');
      if (!myHeatNoProducts) {
        this.dialog.open(MeinepopupComponent, {
          data: {
            type: 'noProducts',
          }
        })
      } else if (this.addresss?.some(e => e.myheating.isHeating == true) && !this.fromAdreses?.show) {
        this.dialog.open(MeinepopupComponent, {
          data: {
            type: 'meinepopup',
          }
        })
      }
      else {
        this.heatingOverview = false
        let meineopen = this.dialog.open(MeinepopupComponent, {
          data: {
            type: 'meinepopup',
            address: this.fromAdreses
          }
        });
        meineopen.afterClosed().subscribe(async (data: any) => {
          // alert("data")
          if (data.address) {
            this.naivateToAdresses.emit(true);
          }
        });
      }
    });
  }

  openHyperlink() {
    let meineopen = this.dialog.open(MeinepopupComponent, {
      data: {
        type: 'hyperlink'
      }
    });
    meineopen.afterClosed().subscribe(async (data: any) => {
    });
  }

  heatingSystemInfo: any;
  ngAfterViewInit() {

  };

  unitsName: any = "l"
  prepareBarChartHere(selectAddr) {
    console.log(selectAddr);
    let addId = {
      addId: selectAddr?._id,
    };

    this.inventoryService.getbarGraphData(addId).subscribe((result: any) => {
      this.heatingSystemInfo = result.data;
      // console.log(this.heatingSystemInfo)
      let consumptionPastData = result.data.pastConsumption;
      let consumptionFutureData = result.data.futureConsumption;
      const primaryColour = getComputedStyle(document.documentElement).getPropertyValue('--primaryColour').trim();
      let monthsNames = ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
      let xAxisMonths = [];
      let yAxisAmount = [];

      if (consumptionPastData.length) {
        consumptionPastData.forEach((m, i) => {
          xAxisMonths.push(monthsNames[m._id.month - 1]);
          yAxisAmount.push({
            y: m.consumption,
            color: primaryColour
          });
        });
        // consumptionPastData.sort(function (a, b) {
        //   let c: any = new Date(a.weatherDate);
        //   let d: any = new Date(b.weatherDate);
        //   return c - d;
        // });
      };

      if (consumptionFutureData.length) {
        consumptionFutureData.forEach((m, i) => {
          if (m) {
            xAxisMonths.push(monthsNames[m._id.month - 1]);
            yAxisAmount.push({
              y: m.consumption,
              color: 'gray'
            });
          }
        });

        // consumptionFutureData.sort(function (a, b) {
        //   let c: any = new Date(a.weatherDate);
        //   let d: any = new Date(b.weatherDate);
        //   return c - d;
        // });
      };
      // console.log(consumptionFutureData, consumptionPastData, 158)
      let maxYaxisVal = 0;
      for (let i = 0; i < yAxisAmount.length; i++) {
        if (yAxisAmount[i].y > maxYaxisVal) {
          maxYaxisVal = yAxisAmount[i].y;
        };
      };

      Highcharts.chart('bar-chart-container', {
        chart: {
          type: 'column'
        },
        title: {
          text: this.languageData['Energie']['VarKundeportalEnergie8'] || 'Monatsverbraacuch',
          align: 'left',
          style: {
            fontSize: '20px',
          }
        },
        xAxis: {
          categories: xAxisMonths,
          crosshair: true,
          title: {
            text: null
          }
        },
        yAxis: {
          lineWidth: 1,
          min: 0,
          max: maxYaxisVal,
          title: {
            text: null
          }
        },
        tooltip: {
          // headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          // pointFormat: '<tr><td style="padding:0"><b>{point.y:.1f} units</b></td></tr>',
          headerFormat: `
          <table>
          <span style="font-size:10px">
             {point.key}
          </span>`,
          pointFormat: `
          <tr>
          <td style="padding:0">
             <b>{point.y:.1f} ${this.unitsName}</b>
          </td>
          </tr>`,
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        series: [{
          type: 'column',
          showInLegend: false,
          data: yAxisAmount
        }]
      });
      // this.realConsumptionChartDraw();

    });
  }

  getAddresses(): Promise<void> {
    console.log(new Error().stack);
    return new Promise<void>((resolve, reject) => {
      this.adminService.getPortaladdresses().subscribe(async (result: any) => {
        if (result.success) {
          this.addresss = result.data.sort((a, b) => a.isPrimary ? -1 : 1);

          if (this.selectAdress && Object.keys(this.selectAdress).length) {
            this.selectAdress = this.addresss.find(ele => ele._id == this.selectAdress?._id)
          } else {
            this.selectAdress = this.addresss.find(ele => ele.myheating?.isHeating);
          };

          if (!this.selectAdress) {
            this.setupView = true;
          } else {
            let addressId = this.selectAdress.invData.addressId;
            console.log(addressId, "addressId", 78);
            await this.getLineGraphData(addressId);
          };
          this.heatingOverview = !!this.selectAdress;
          console.log(this.heatingOverview, 54);

          // this.addresss = this.heatingOverview ? this.addresss.filter(data => data?._id !== this.selectAdress?._id) : [];
          if (this.selectAdress) {
            this.prepareBarChartHere(this.selectAdress);
          };
          resolve(result);
        } else {
          this.addresss = [];
          reject(result);
        };
      });
    });
  };

  openAdress() {
    // this.setupView = false;
    // this.getAddresses().then(res => { 
    //   this.showAdresses = true;
    //   this.setupView = false;
    // });
    let dialog = this.dialog.open(AddresspopupComponent, {
      data: {
        address: this.addresss,
        width: '550px',
        height: '500px'
      }
    })
    dialog.afterClosed().subscribe(async (data: any) => {
      console.log(data, 888);
      this.natigateSetup(data?.index);
    })
  };

  initalizeProcess(address) {
    let data: any = {
      type: 'meinepopup',
      edit: false,
    }
    if (address && Object.keys(address).length) {
      data = { ...data, edit: true, "address": address }

    }
    console.log(data, 999);
    let heatingSystem = this.dialog.open(
      MeineHeizungSetupComponent,
      {
        data: data
      });
    heatingSystem.afterClosed().subscribe(async (resp: any) => {

      // saveMyHeatingresp
      if (data.edit) {
        this.saveMyHeatingData(resp.data)
      }
      if (!data.edit && resp.success) {
        this.getAddresses().then(res => {
          console.log(res, 9334);
          console.log("address received", 78)
        });
      } else {
      };
    });
  };

  natigateSetup(index) {
    let setupAdress = this.addresss[index];
    if (setupAdress?.myheating?.isHeating) {
      this.selectAdress = setupAdress;
      setTimeout(() => {
        this.dashboardSPLineChart();
        this.prepareBarChartHere(this.selectAdress);
      }, 1000);

      this.heatingOverview = true;
      this.showAdresses = false;
    } else {
      this.heatingOverview = false;
      this.showAdresses = false;
      this.setupView = true;
      this.select = this.addresss.some(e => e.myheating.isHeating == true);
    };
  };

  openFilling() {
    let meineopen = this.dialog.open(MeinepopupComponent, {
      data: {
        width: '550px',
        type: 'tankFill',
        heatingData: this.selectAdress.myheating
      }
    });

    meineopen.afterClosed().subscribe(async (data: any) => {
      if (data.save) {
        this.selectAdress.myheating.currentActiveLevel = data.data.newFuelLevel;
        this.inventoryService.saveIventoryFilling({ data: data.data, type: 'fill', addressId: this.selectAdress._id }).subscribe(async data => {
          // console.log(data);
          if (data.success) {
            await this.getAddresses();
          } else {
            await this.getAddresses();
          };
        });
      } else {
        // this.getAddresses();
      };
    });
  };

  openBestands() {
    // console.log(this.selectAdress.myheating ,5454);
    let meineopen = this.dialog.open(
      MeinepopupComponent,
      {
        data: {
          width: '550px',
          type: 'Bestandskontrolle',
          heatingData: this.selectAdress.myheating
        }
      });

    meineopen.afterClosed().subscribe(async (data: any) => {
      if (data.save) {
        this.inventoryService.saveIventoryFilling({ data: data.data, type: 'Inventory', addressId: this.selectAdress._id }).subscribe(data => {
          // console.log(data);
          if (data.success) {
            this.getAddresses();
          } else {
            this.getAddresses();
          };
        });
      } else {
        // this.getAddresses();
      };
    });
  };

  getPercentageLevel(heatingData) {
    const level = typeof heatingData.activePercentageLevel === 'string' ? Number(heatingData.activePercentageLevel) : heatingData.activePercentageLevel;
    const percentage = `${(level * 3 / 4)} 75`;
    const circleRadius = 15.91549430918954;
    const circleCentreXY_AXIS = 21;
    const totalDegrees = 360 * 0.75;

    const getIndicator = (reminderValue, offset, label) => {
      const reminderPercentage = (reminderValue * 100) / heatingData.maxTankSize;
      const percentageOffset = (reminderPercentage + (offset * 3 / 4));
      const angle = (percentageOffset / 100) * totalDegrees;
      const radian = angle * (Math.PI / 180);
      const x = circleCentreXY_AXIS + circleRadius * Math.cos(radian - Math.PI / 2);
      const y = circleCentreXY_AXIS + circleRadius * Math.sin(radian - Math.PI / 2);
      return `
        <circle width="10" height="10" cx="${x}" cy="${y}" r="2.5" fill="white" stroke="gold" stroke-width="0.8" />
        <text x="${x}" y="${y}" text-anchor="middle" alignment-baseline="middle" style="font-size: 2px; fill: black;font-weight:bold">${label}</text>`;
    };

    const firstIndicator = heatingData?.hasWarning1 && heatingData?.reminderOneValue ? getIndicator(heatingData?.reminderOneValue, 288, '1') : '';
    const secondIndicator = heatingData?.hasWarning2 && heatingData?.reminderTwoValue ? getIndicator(heatingData?.reminderTwoValue, 290, '2') : '';

    const svgContent = `
    <svg style="position: relative; right: 5px;" width="250px" height="250px" viewBox="0 0 42 42">
      <path
        d="M21 21 m-11.254 11.254 a 15.91549430918954 15.91549430918954 135 1 1 22.508 0"
        fill="transparent" stroke-linecap="round" style="stroke: #ddd; stroke-width: 2; fill: transparent; position: relative; right: 5px;" />
      <path
        d="M21 21 m-11.254 11.254 a 15.91549430918954 15.91549430918954 135 1 1 22.508 0"
        fill="transparent" stroke-linecap="round"
        style="stroke: var(--primaryColour); stroke-width: 2; fill: transparent; stroke-dasharray: ${percentage}; transition: stroke-dasharray 0.3s;" />
        ${firstIndicator} 
        ${secondIndicator}
    </svg>`;

    return this.sanitizer.bypassSecurityTrustHtml(svgContent);
  }


  clickToOverview() {
    this.heatingOverview = true;
  };

  public errorMessage: string;
  consumptionForms: FormGroup[] = [];

  addConsumption() {
    console.log("add NewRow");
    // this.editble = true;
    this.addNewEntry = true;
    // Clear the form array if it's a FormArray
    if (this.consumptionForms instanceof FormArray) {
      this.consumptionForms.clear();
    }
    // Or set it to an empty array if it's a standard array
    else {
      this.consumptionForms = [];
    };

    // Then populate the form array
    !this.realverbrauch.length && this.realverbrauch.push(
      {
        "year": '',
        "total": ''
      }
    );

    this.realverbrauch.forEach(consumption => {
      if (consumption.year !== undefined && consumption.total !== undefined) {
        const formGroup = this.fb.group({
          year: [[consumption.year], [Validators.required, Validators.min(1970), Validators.max(new Date().getFullYear() - 1), Validators.pattern('^[0-9]*$')]],
          total: [consumption.total, [Validators.required, Validators.pattern('^[0-9]*$')]]
        });
        this.consumptionForms.push(formGroup);

        // formGroup.statusChanges.subscribe(status => {
        //   console.log(status,281)
        //   if (status === 'INVALID') {
        //     this.errorMessage = 'Nur positive und ganzzahlige Eingaben akzeptiert';
        //   } else {
        //     this.errorMessage = null;
        //   }
        // });
      };
    });
    // const dialog = this.dialog.open(CosumptionComponent, {
    //   width: '250px',
    //   data: this.realverbrauch
    // })
    // dialog.afterClosed().subscribe((result) => {
    //   console.log("result", result);
    //   if (result) {
    //     console.log("inside if condition", result);
    //     let consumptionData = result.filter(value => value.total !== null && value.total !== undefined && value.year != '' && !isNaN(value.total)).map(value => value.total);
    //     console.log("consumption data", consumptionData);
    //     this.realverbrauch = result;
    //     console.log("result", result);
    //     this.realConsumptionYears = result.map((value) => value.year);
    //     console.log("consumption years", this.realConsumptionYears);

    //     this.realConsumptionData = result.filter(value => value.total !== null && value.total !== undefined && !isNaN(value.total)).map(value => value.total);
    //     console.log("consumption data", this.realConsumptionData);
    //     this.realConsumptionChartDraw();
    //   } else {
    //     console.log("inside else condition", result);
    //     console.log("no data", this.realConsumptionData);
    //     console.log("no data year", this.realConsumptionYears);
    //   }
    // })
  };

  realConsumptionChartDraw() {
    // Highcharts.chart('realConsumption-chart-container', {
    //   chart: {
    //     type: 'line'
    //   },
    //   title: {
    //     text: this.languageData['Energie']['VarKundeportalEnergie7'] || 'Jahresverbrauch',
    //     align: 'left',
    //     style: {

    //       fontSize: '20px',
    //       textAlign: 'left'
    //     }
    //   },
    //   xAxis: {
    //     categories: this.realConsumptionYears,
    //     title: {
    //       text: null
    //     }
    //   },
    //   yAxis: {
    //     lineWidth: 1,
    //     min: 0,
    //     max: 9000,
    //     title: {
    //       text: null
    //     }

    //   },
    //   plotOptions: {
    //     line: {
    //       dataLabels: {
    //         enabled: true
    //       },
    //       enableMouseTracking: false
    //     }
    //   },
    //   series: [{
    //     type: 'line',

    //     showInLegend: false,
    //     data: this.realConsumptionData
    //   }]
    // });
    //bar chart2
    const currentMonth = new Date().getMonth();
    const primaryColour = getComputedStyle(document.documentElement).getPropertyValue('--primaryColour').trim();
    const data = [350, 190, 80, 110, 200, 400].map((value, index) => {
      return {
        y: value,
        color: primaryColour
      };
    });

    Highcharts.chart('realConsumption-chart-container', {
      chart: {
        type: 'column'
      },
      title: {
        text: this.languageData['Energie']['VarKundeportalEnergie7'] || 'Jahresverbrauch',
        align: 'left',
        style: {
          fontSize: '20px',
        }
      },
      xAxis: {
        categories: this.realConsumptionYears,
        crosshair: true,
        title: {
          text: null
        }
      },
      yAxis: {
        lineWidth: 1,
        min: 0,
        max: 500,
        title: {
          text: null
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="padding:0"><b>{point.y:.1f} units</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [
        {
          type: 'column',
          showInLegend: false,
          data: data
        }
      ]
    });
  };

  onCancel(): void {
    this.addNewEntry = false;
    this.realverbrauch = this.realverbrauch.filter(value => value.total !== null && value.total !== undefined && value.year != '' && !isNaN(value.total)).map(value => value.total);
    this.errorMessage = '';
  };

  onSave() {
    this.errorMessage = '';
    const emptyForms = this.consumptionForms.filter(form => form.value.year === '' || form.value.total === '');
    if (emptyForms.length > 0) {
      this.errorMessage = 'Bitte machen Sie eine gültige Jahres- und Verbrauchseingabe!';
      return;
    };
    const inValidForms = this.consumptionForms.filter(form => form.invalid);
    if (inValidForms.length > 0) {
      inValidForms.forEach(form => {
        if (form.value.year && !this.isOnlyNumbers(form.value.total)) {
          this.errorMessage = 'Nur positive und ganzzahlige Eingaben akzeptiert';
        }
      })
      return
    };

    const values = this.consumptionForms.map(form => ({ "year": form.value.year, "total": parseInt(form.value.total) }));
    // let filteredData = values.filter(value => value.year !== undefined && value.total !== undefined && value.year !== '');
    // console.log(filteredData);
  };

  isOnlyNumbers(str) {
    const regex = /^[0-9]*$/;
    return regex.test(str);
  };

  @ViewChild('chartContainer', { static: false }) chartContainer: ElementRef;
  today = new Date();

  dashboardSPLineChart() {
    if (this.chartContainer) {
      let currentDate = new Date().setHours(0, 0, 0, 0); // current date at 00:00:00
      this.lineGraphData.sort((a, b) => a[0] - b[0]);

      let currentDateIndex = this.lineGraphData.findIndex(point => new Date(point[0]).setHours(0, 0, 0, 0) > currentDate); // index of the first point that is in the future
      const primaryColour = getComputedStyle(document.documentElement).getPropertyValue('--primaryColour').trim();
      // If all points are in the past, set currentDateIndex to the length of the data
      if (currentDateIndex === -1) {
        currentDateIndex = this.lineGraphData.length;
      }

      const maxCapacity = this.lineGaraphObj['maxCapacity'];
      const maxYAxisValue = Math.ceil(maxCapacity / 1000) * 1000;

      const lineValues = [1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000];
      const plotLines = lineValues.map((value, index) => ({
        value: value,
        zIndex: 5,
        color: 'rgba(230, 230, 230, 1)',
        width: 1,
      }));

      Highcharts.setOptions({
        lang: {
          months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
          shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
          decimalPoint: ',',
          thousandsSep: '.'
        }
      });

      Highcharts.chart(this.chartContainer.nativeElement, {
        chart: {
          type: 'line',
          events: {
            load: function () {
              var chart = this,
                points = chart.series[0].points,
                zones = [],
                color,
                maxValue,
                maxValue2,
                chosenPoint,
                chosenPoint2;
              // function addZone(value, color) {
              //   zones.push({
              //     value: value,
              //     color: color
              //   });
              // }
              let i = 0;
              points.forEach(function (point, index) {
                // console.log(point.x, new Date(point.x).getDate(), new Date().getDate(), 78)
                if (new Date(point.x).getDate() == new Date().getDate() && new Date(point.x).getMonth() == new Date().getMonth()) {
                  maxValue = point.y;
                  chosenPoint = point;
                }
              });

              chosenPoint?.update(
                {
                  marker: {
                    enabled: true,
                    symbol: 'circle', fillColor: 'red', radius: 5,
                    linewidth: 2,
                  }
                }
              );
            }
          }
        },

        title: {
          text: 'Bestandsentwicklung',
          align: 'left',
          style: {
            fontSize: '18px',
            textAlign: 'left'
          }
        },
        subtitle: {
          text: `Max ${maxCapacity.toLocaleString('de-DE')} liter`,
          align: 'left',
          style: {
            fontSize: '14px',
            textAlign: 'left',
            color: '#333'
          }
        },
        xAxis: {
          tickWidth: 0,
          type: 'datetime',
          dateTimeLabelFormats: {
            // day: '%e of %b'
            month: '%b',
          },
          tickInterval: 30 * 24 * 3600 * 1000,
          currentDateIndicator: true,

          plotLines: [
            {
              color: 'Gold',
              width: 2,
              zIndex: 9999999,
              value: new Date(this.lineGaraphObj['warningAlertDate']).getTime(),
              dashStyle: 'Dash',
              label: {
                text: `${this.lineGaraphObj['reminderRange']} Tage Warnung`,
                align: 'right',
                rotation: 0,
              },
            }
          ]
        },
        yAxis: {
          title: {
            text: null
          },
          max: maxYAxisValue,
          lineWidth: 1,
          // startOnTick: true,
          // labels: {
          //   enabled: false,
          // },
          gridLineWidth: 0,
          min: 0,
          labels: {
            formatter: function () {
              return this.value.toLocaleString('de-DE');
            }
          },
          plotLines: plotLines

        },
        plotOptions: {
          series: {
            lineWidth: 5,
            // point: {
            //   events: {
            //     mouseOver: function () {
            //       this.graphic.hide();
            //     },
            //     mouseOut: function () {
            //       this.graphic.hide();
            //     }
            //   }
            // },


            dataLabels: {
              enabled: false,
              overflow: "justify",
            },
          },

        },
        tooltip: {
          enabled: false,
        },
        // series: [{
        //   data: this.lineGaraphObj['past'],
        //   color: 'blue', // color for the past data points
        // }, {
        //   data: this.lineGaraphObj['future'],
        //   color: 'grey', // color for the future data points
        // }],
        series: [
          {
            marker: {
              radius: 0,
            },
            data: this.lineGaraphObj['past'],
            lineWidth: 1,
            type: 'line',
            showInLegend: false,
            color: primaryColour, // color for the past data points
            // zones: [
            //   {
            //     value: this.lineGraphData[currentDateIndex - 1][0], // x-value of the last point that is not in the future
            //     color: 'blue', // color up to the current date
            //   },
            //   {
            //     color: 'grey', // color after the current date
            //   },
            // ]

            // zones: (() => {
            //   let zones = [];
            //   this.lineGraphData.forEach(point => {
            //     let date = new Date(point[0]).setHours(0, 0, 0, 0);
            //     if (date <= currentDate) {
            //       zones.push({
            //         value: this.lineGraphData[currentDateIndex][0],
            //         color: '#39baea'
            //       });
            //     } else {
            //       zones.push({
            //         color: 'grey'
            //       });
            //     }
            //   });
            //   console.log(zones); // Log zones for debugging
            //   return zones;
            // })()
          },

          {
            marker: {
              radius: 0,
            },
            data: this.lineGaraphObj['future'],
            lineWidth: 1,
            type: 'line',
            showInLegend: false,
            color: 'gray',
          }]
      });
    } else {
      console.log("chartContainer not found", 78)
    };
  };

  series = [];
  async getLineGraphData(addressId) {
    this.inventoryService.getLineGraphData({ addressId: addressId }).subscribe((result: any) => {
      if (result.success) {
        this.lineGaraphObj['lineGraphData'] = result.data[0]['transformedGraphData'];
        this.lineGaraphObj['maxCapacity'] = result.data[0]['tankSize'];
        this.lineGaraphObj['past'] = result.data[0]['pastData'];
        this.lineGaraphObj['future'] = result.data[0]['futureData'];
        this.lineGraphData = result.data[0]['transformedGraphData'];
        this.lineGraphData = this.lineGraphData.filter(point => point[1] >= 0)
        this.series = this.lineGraphData.map(point => {
          return {
            value: point[0],
            color: new Date(point[0]) > new Date() ? 'blue' : 'red',
            // fillColor: new Date(point[0]) >  new Date()? 'blue' : 'red',
          }

        });
        this.lineGaraphObj['warningAlertDate'] = result.data[0]['warningAlertDate'];
        this.lineGaraphObj['reminderRange'] = result.data[0]['reminderRange'];
        setTimeout(() => {
          this.dashboardSPLineChart();
        }, 1000)
      } else {
        console.log("error", result)
      }
    });
  };


  editHeating() {
    console.log(this.selectAdress, 544);  
    let procesData = { ...this.selectAdress.invData, weatherId: this.selectAdress.weatherId, sunhourStation: this.selectAdress.sunhourStation };
    console.log(procesData, 545);
    this.initalizeProcess(procesData);

  }

  saveMyHeatingData(data) {
    console.log(data, 545);
    Object.entries(data).forEach(([key, value]) => {
      if (typeof value === 'string' && value !== '') {
        data[key] = parseInt(value, 10);
      }
    });
    ['stageFour', 'stageThree', 'fillingData',
      'stageTwo', 'weatherId', 'unternehmenId', 'QA',
      'myMasterId', 'consumptionInKWH', 'createdAt',
      'gtzValue', 'kundenKontoId', 'lastFuelUpdatedDate', '_id'].forEach(property => {
        if (data.hasOwnProperty(property)) {
          delete data[property];
        }
      });
    console.log(data, 45445);
    data = { ...data, addressId: this.selectAdress._id };
    console.log(data, 65654);
    this.showFullScreenLoading = true;
    this.adminService.saveInventoryData({ inventoryData: data }).subscribe(resp => {
      if (resp && resp.success) {
        this.showFullScreenLoading = false;
        this.getAddresses();
      } else {
        this.showFullScreenLoading = false;
      }
    });
  };

}
