<div class="pc-sideMenu">
    <div class="sidemenu sidealign" *ngIf="!menubutton" [ngStyle]="{
            'width':rightAroow === false ? '300px' : '100px',
        'text-align':rightAroow == false ? 'center' :'right' 
    }">

        <div class="toggleBtn backi cPointer" (click)="close()">
            <span class="toggleIcon" *ngIf="buttontag">
                <span [innerHTML]="arrowIn"></span>
            </span>
            <span class="toggleIcon" *ngIf="!buttontag">
                <span [innerHTML]="arrowOut"></span>
            </span>
        </div>


        <button class="menuh sideMenuBar btnback cPointer" (click)="closemenu()">
            <span class="toggleIcon backalign">
                <span [innerHTML]="BackIcon"></span>
            </span>
        </button>

        <div class="cPointer">
            <ul>
                <a>
                    <li class="item-menu">
                        <span>
                            <span *ngIf="!photoUrl" class="name-shortcut photop">
                                {{userInfo?.Vorname | nameInitials : userInfo?.Name}}
                            </span>
                            <span *ngIf="photoUrl"
                                style="align-items: center;display: inline-grid;padding-top: 3px;background-color: white;">
                                <img style="width:50px;height:50px;border-radius:50%; margin-top: -8%;"
                                    [src]="photoUrl">
                            </span>
                        </span>

                        <span class="menu backi" *ngIf="buttontag">
                            {{userInfo?.Vorname}} {{userInfo?.Name}}
                        </span>

                        <div class="menu menuh name" *ngIf="buttontag">
                            <span>{{userInfo?.Vorname | truncate:[12,'...']}} </span>
                            <br>
                            <span>{{userInfo?.Name | truncate:[12,'...']}}</span>
                        </div>
                    </li>
                </a>
                <a>
                    <li class="item-menu dashboard" (click)="changeView('Dashboard')" (mouseover)="dashboardHover=true"
                        (mouseleave)="dashboardHover=false"
                        [ngClass]="{'active': (activetab == 'Dashboard' || dashboardHover == true)}"
                        [ngStyle]="{'color': (activetab == 'Dashboard' || dashboardHover == true) ? primaryColor : 'black' }">
                        <span
                            [ngStyle]="{'fill': (activetab == 'Dashboard' || dashboardHover == true) ? primaryColor : 'black' }">
                            <span *ngIf="activetab == 'Dashboard' || dashboardHover">
                                <span [innerHTML]="dashboard1" *ngIf="dashboard1">
                                </span>
                            </span>
                            <span *ngIf="activetab != 'Dashboard' && !dashboardHover">
                                <span [innerHTML]="dashboard" *ngIf="dashboard">
                                </span>
                            </span>
                        </span>

                        <span style="padding-left: 18px;" *ngIf="buttontag"
                            [ngClass]="{'active': (activetab == 'Dashboard' || dashboardHover == true)}">Dashboard</span>

                    </li>
                </a>
                <!-- <a>
                    <li class="item-menu prical" (click)="changeView('InventoryTracking')"
                        (mouseover)="createInventoryTrackingHover=true"
                        (mouseleave)="createInventoryTrackingHover=false"
                        [ngClass]="{'active': (activetab == 'InventoryTracking' || createInventoryTrackingHover == true)}"
                        [ngStyle]="{'color': (activetab == 'InventoryTracking' || createInventoryTrackingHover == true) ? primaryColor: 'black' }">
                        <span style="position: relative;right: 2px;"
                            [ngStyle]="{'fill': (activetab == 'dynamicPcs' || createInventoryTrackingHover == true) ? primaryColor: 'black' }">
                            <span *ngIf="activetab == 'InventoryTracking' || createInventoryTrackingHover">
                                <span [innerHTML]="HomeHeater">
                                </span>
                            </span>
                            <span *ngIf="activetab != 'InventoryTracking' && !createInventoryTrackingHover">
                                <span [innerHTML]="noHomeHeater">
                                </span>
                            </span>
                        </span>
                        <span style="padding-left: 14px;" *ngIf="buttontag"
                            [ngClass]="{'active': (activetab == 'InventoryTracking' || createInventoryTrackingHover == true)}">
                            {{languageData['Dashboard']?.VarKundeportalDashboard1|| 'Meine Heizung'}}
                        </span>
                    </li>
                </a>
                <a>
                    <li class="item-menu prical" (click)="changeView('dynamicPcs')" (mouseover)="dynamicPcHover=true"
                        (mouseleave)="dynamicPcHover=false"
                        [ngClass]="{'active': (activetab == 'dynamicPcs' || dynamicPcHover == true)}"
                        [ngStyle]="{'color': (activetab == 'dynamicPcs' || dynamicPcHover == true) ? primaryColor: 'black' }">
                        <span
                            [ngStyle]="{'fill': (activetab == 'dynamicPcs' || dynamicPcHover == true) ? primaryColor: 'black' }">
                            <span *ngIf="activetab == 'dynamicPcs' || dynamicPcHover">
                                <span [innerHTML]="pcLogo" *ngIf="pcLogo">
                                </span>
                            </span>
                            <span *ngIf="activetab != 'dynamicPcs' && !dynamicPcHover">
                                <span [innerHTML]="noPcLogo" *ngIf="noPcLogo">
                                </span>
                            </span>
                        </span>
                        <span style="padding-left: 20px;" *ngIf="buttontag"
                            [ngClass]="{'active': (activetab == 'dynamicPcs' || dynamicPcHover == true)}">
                            {{ 'Preisrechner'}}
                        </span>
                    </li>
                </a>

                <a>
                    <li class="item-menu pricew" (click)="changeView('pricealarm')" (mouseover)="preisalarmHover=true"
                        (mouseleave)="preisalarmHover=false"
                        [ngClass]="{'active': (activetab == 'pricealarm' || preisalarmHover == true)}"
                        [ngStyle]="{'color': (activetab == 'pricealarm' || preisalarmHover == true) ? primaryColor: 'black' }">
                        <span
                            [ngStyle]="{'fill': (activetab == 'pricealarm' || preisalarmHover == true) ? primaryColor: 'black' }">
                            <span *ngIf="activetab == 'pricealarm'">
                                <span [innerHTML]="priceAlarm" *ngIf="priceAlarm">
                                </span>
                            </span>
                            <span *ngIf="activetab != 'pricealarm'">
                                <span [innerHTML]="nopricealaram" *ngIf="nopricealaram">
                                </span>
                            </span>
                        </span>

                        <span style="padding-left: 13px;" *ngIf="buttontag"
                            [ngClass]="{'active': (activetab == 'pricealarm' || preisalarmHover == true)}">{{languageData['Hamburger']?.Var_pricealaram
                            || 'Preiswecker'}}</span>
                    </li>
                </a>

                <a>
                    <li class="item-menu order" (click)="changeView('Bestellung')" (mouseover)="bestellungHover=true"
                        (mouseleave)="bestellungHover=false"
                        [ngClass]="{'active': (activetab == 'Bestellung' || bestellungHover == true)}"
                        [ngStyle]="{'color': (activetab == 'Bestellung' || bestellungHover == true) ? primaryColor: 'black' }">
                        <span
                            [ngStyle]="{'fill': (activetab == 'Bestellung' || bestellungHover == true) ? primaryColor: 'black' }">
                            <span *ngIf="activetab == 'Bestellung' || bestellungHover">
                                <span [innerHTML]="orders" *ngIf="orders"></span>
                            </span>
                            <span *ngIf="activetab != 'Bestellung' && !bestellungHover">
                                <span [innerHTML]="noorders" *ngIf="noorders"></span>
                            </span>
                        </span>

                        <span style="padding-left: 18px;" *ngIf="buttontag"
                            [ngClass]="{'active': (activetab == 'Bestellung' || bestellungHover == true)}">{{languageData['Hamburger']?.Var_Bestellung
                            || 'Bestellungen'}}</span>
                    </li>
                </a>

                <a>
                    <li class="item-menu order" (click)="changeView('Adressen')" (mouseover)="adressenHover=true"
                        (mouseleave)="adressenHover=false"
                        [ngClass]="{'active': (activetab == 'Adressen' || adressenHover == true)}"
                        [ngStyle]="{'color': (activetab == 'Adressen' || adressenHover == true) ? primaryColor: 'black' }">
                        <span
                            [ngStyle]="{'fill': (activetab == 'Adressen' || adressenHover == true) ? primaryColor: 'black' }">
                            <span *ngIf="activetab == 'Adressen' ||adressenHover">
                                <span [innerHTML]="adress" *ngIf="adress"></span>
                            </span>
                            <span *ngIf="activetab != 'Adressen' && !adressenHover">
                                <span [innerHTML]="noadress" *ngIf="noadress"></span>
                            </span>
                        </span>
                        <span style="padding-left: 19px;" *ngIf="buttontag"
                            [ngClass]="{'active': (activetab == 'Adressen' || adressenHover == true)}">{{languageData['Hamburger']?.Var_Adressen
                            ||
                            'Adressen'}}</span>
                    </li>
                </a>

                <a>
                    <li class="item-menu prical" (click)="changeView('Einstellungen')"
                        (mouseenter)="einstellungenHover=true" (mouseout)="einstellungenHover=false"
                        [ngClass]="{'active': (activetab == 'Einstellungen' || einstellungenHover == true)}"
                        [ngStyle]="{'color': (activetab == 'Einstellungen' || einstellungenHover == true) ? primaryColor: 'black' }">
                        <span
                            [ngStyle]="{'fill': (activetab == 'Einstellungen' || einstellungenHover == true) ? primaryColor: 'black' }">

                            <span *ngIf="activetab == 'Einstellungen'|| einstellungenHover">
                                <span [innerHTML]="settings" *ngIf="settings"></span>
                            </span>
                            <span *ngIf="activetab != 'Einstellungen' && !einstellungenHover">
                                <span [innerHTML]="nosettings" *ngIf="nosettings"></span>
                            </span>
                        </span>
                        <span style="padding-left: 18px;" *ngIf="buttontag"
                            [ngClass]="{'active': (activetab == 'Einstellungen' || einstellungenHover == true)}">{{languageData['Hamburger']?.Var_Einstellungen
                            || 'Einstellungen'}}</span>
                    </li>
                </a>

                <a>
                    <li class="item-menu pricew" (click)="changeView('Logout');" (mouseover)="logoutHover=true"
                        (mouseleave)="logoutHover=false"
                        [ngClass]="{'active': (activetab == 'Logout' || logoutHover == true)}"
                        [ngStyle]="{'color': (activetab == 'Logout' || logoutHover == true) ? primaryColor: 'black' }">
                        <span
                            [ngStyle]="{'fill': (activetab == 'Logout' || logoutHover == true) ? primaryColor: 'black' }">
                            <span [innerHTML]="logout" *ngIf="logout"></span>
                        </span>
                        <span style="padding-left: 18px;" *ngIf="buttontag"
                            [ngClass]="{'active': (activetab == 'Logout' || logoutHover == true)}">Logout</span>
                    </li>
                </a> -->

            </ul>
        </div>

        <!-- <button class="lastbtn buttonCss hotline" *ngIf="hotlineStatus" (click)="emergency()"
            [style.padding-left.px]='(buttontag ==false ?15:0)' [style.padding-right.px]='(buttontag ==false ?15:0)'
            [style.width.px]='(buttontag ==false ?50:193)' [ngClass]="buttontag ==false ? 'smobile':'bmobile'"
            [style.right.%]='(buttontag ==false ?0:6)' [style.margin-right.px]='(buttontag ==false?10:0)'
            [style.background]="primaryColor">
            <span class="texthotline">
                <span [innerHTML]="hotline" *ngIf="hotline"></span>
                <span style="padding-left: 5px;padding-right: 8px;font-size: 14px;vertical-align: middle;"
                    *ngIf="buttontag"
                    [ngStyle]="{color: 'var(--primaryTextColour)'}">{{languageData['Hamburger']?.Var_emergencyHotline
                    || 'Notfall Hotline'}}</span>
            </span>
        </button> -->
    </div>
</div>