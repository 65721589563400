<div id="grid" class=""
    [ngClass]="{'stage1': currentStage === 1, 'stage2': currentStage === 2, 'stage3': currentStage === 3, 'stage4': currentStage === 4}"
    style="margin:10px 0px 0px 0px">
    <div class="dummycircle" *ngIf="currentStage < 2"></div>
    <div id="circle1">
        <ng-container>
            <span style="padding: 4px 2px 3px 2px;">
                {{currentStage === 1 ? 1 :'✓'}}
            </span>
        </ng-container>
    </div>
    <div id="circle2" *ngIf="currentStage <= 2">
        <ng-container>
            <span style="padding: 4px 2px 3px 2px;">
                2
            </span>
        </ng-container>
    </div>
    <div id="circle2" *ngIf="currentStage > 2">
        <ng-container>
            {{currentStage === 2 ? 2 :'✓'}}
        </ng-container>
    </div>
    <div id="circle3" *ngIf="currentStage <= 3">
        <ng-container>
            <span style="padding: 4px 2px 3px 2px;">
                3
            </span>
        </ng-container>
    </div>
    <div id="circle3" *ngIf="currentStage > 3">
        <ng-container>
            {{currentStage === 3 ? 3 :'✓'}}
        </ng-container>
    </div>
    <div id="circle4">
        <ng-container>
            <span style="padding: 4px 2px 3px 2px;">
                4
            </span>
        </ng-container>
    </div>
    <div class="dummycircle" *ngIf="currentStage > 3"></div>
</div>
<hr class="hrCircle">