<h2 mat-dialog-title>Realverbrauch</h2>
<mat-dialog-content>
    <div *ngFor="let form of consumptionForms; let i = index" [formGroup]="form" class="consumption-row">
        <div>
            <div class="input-d margin-bottom">
                <input class="auform-control" formControlName="year">
                <span>
                    <input class="auform-control" formControlName="total">KG
                </span>

            </div>
            <div class="input-error"
                *ngIf="form.controls.year.dirty && form.controls.year.invalid && form.controls.total.pristine">
                Year is required
            </div>

        </div>
    </div>
    <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
</mat-dialog-content>

<div>
    <button class="au-btn left" (click)="onCancel()">Abbrechen</button>
    <button class="au-btn right" (click)="onSave()">Speichern</button>
</div>