import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AdminService } from 'app/Services/admin.service';
import { SmallPcService } from '../Services/small-pc.service';
import { BigpcService } from 'app/Services/bigpc.service';
import { environment } from 'environments/environment';
import { LanguageService } from '../Services/language.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import { MatDialog } from '@angular/material/dialog';
import { MeinepopupComponent } from 'app/popups/meinepopup/meinepopup.component';
import { WindowRefService } from 'app/Services/window.service';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

let prepareDummayData = [
  {
    id: 1,
    date: new Date(),
    content: "Our free Paraphrasing tool offers several important features",
    imgUrl: "https://material.angular.io/assets/img/examples/shiba2.jpg",
  },
  {
    id: 2,
    date: new Date(),
    content: "Our free Paraphrasing tool offers several important features",
    imgUrl: "https://material.angular.io/assets/img/examples/shiba2.jpg",
  },
  {
    id: 3,
    date: new Date(),
    content: "Our free Paraphrasing tool offers several important features",
    imgUrl: "https://material.angular.io/assets/img/examples/shiba2.jpg",
  },
  {
    id: 4,
    date: new Date(),
    content: "Our free Paraphrasing tool offers several important features",
    imgUrl: "https://material.angular.io/assets/img/examples/shiba2.jpg",
  }
]

@Component({
  selector: 'app-tracking-dashboard',
  templateUrl: './tracking-dashboard.component.html',
  styleUrls: ['./tracking-dashboard.component.css']
})
export class TrackingDashboardComponent implements OnInit {

  newsData: any = [];
  address: any;
  url: any;
  isPrimary: boolean = false;
  isHeating: boolean = false;
  dashboardData: any;
  portaluser_data: any;
  onlineKonto: any;
  languageData: any;
  private _window: any;
  @Output() showPricecalcFromPrimayAddress = new EventEmitter();
  @Output() showAllOrders = new EventEmitter();
  tankerImg = "assets/inventory/befullung.svg";
  quntityImg = "src/assets/inventory/Bestandskontrolle.svg";
  percentageLevelData = {
    productId: 1,
    currentDate: new Date(),
    maxTankSize: 8000,
    activePercentageLevel: 75,
    currentActiveLevel: 2855,
    remainingDays: 238,
    hasWarning1: true,
    hasWarning2: true
  }

  svgs = [
    environment.assetsUrl + 'assets/inventory/befullung.svg',
    environment.assetsUrl + 'assets/inventory/Bestandskontrolle.svg',
    environment.assetsUrl + 'assets/inventory/preisrechner.svg',
    environment.assetsUrl + 'assets/inventory/MeineHeizung.svg',
    environment.assetsUrl + 'assets/Trackingofinventoriessvgs/Fire.svg',
    environment.assetsUrl + 'assets/Trackingofinventoriessvgs/Pellets.svg',
    environment.assetsUrl + 'assets/Trackingofinventoriessvgs/Gas.svg',
    environment.assetsUrl + 'assets/svgs/dashboarduser.svg',
  ];
  orders: any;

  constructor(
    public adminService: AdminService,
    private sampleService: SmallPcService,
    private newBigPc: BigpcService,
    private langService: LanguageService,
    public httpClient: HttpClient,
    public sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private windowRef: WindowRefService,
  ) {
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });
    this._window = this.windowRef.nativeWindow;
    this.url = this._window.location.href;
    this.getDynamicSVG(this.svgs);
  };

  ngOnInit(): void {
    this.getPrimaryAddress();
    this.getAddresses();
    this.getDashboardData();
    this.getLatestOrders();
    this.newsData = prepareDummayData;
    let portalUser = JSON.parse(localStorage.getItem("portal_user"));
    this.onlineKonto = portalUser.portalOnlineId;
    // this.createChartGauge();
    // this.createChartForQuantity();
    // this.createChartForOrders();
    // this.createChartPie();
    // this.createChartColumn();
  };

  ngAfterViewInit() {

    setTimeout(() => {
      this.dashboardSPLineChart();
      // this.createChartForOrders();
    }, 2000)

  };

  // public ngAfterViewInit(): void {
  //   this.createChartGauge();
  //   this.createChartPie();
  //   this.createChartColumn();
  //   this.createChartLine();
  // };

  getDynamicSVG(svgs) {
    let icons = []
    svgs.forEach((svgItem) => {
      let item = svgItem;
      let first = item.split('.svg')[0];
      let iconNameArray = first.split('/');
      let iconName = iconNameArray.pop();
      this.httpClient.get(item, { responseType: 'text' }).subscribe((logo) => {
        logo = logo.replace(/#333/ig, 'var(--primaryColour)'); //fill
        logo = logo.replace(/#111/ig, 'var(--highlightColour)'); //storke
        logo = logo.replace(/#444/ig, 'var(--colorShade1)'); //shade1
        logo = logo.replace(/#555/ig, 'var(--colorShade2)'); //shade2
        this[iconName] = this.sanitizer.bypassSecurityTrustHtml(logo);
        icons.push(this[iconName])
      });
    });
  };

  getDynamicSVGStatic(svgImg) {
    let item = svgImg;
    let first = item.split('.svg')[0];
    let iconNameArray = first.split('/');
    let iconName = iconNameArray.pop();
    svgImg = svgImg.replace(/#333/ig, 'var(--primaryColour)'); //fill
    svgImg = svgImg.replace(/#111/ig, 'var(--highlightColour)'); //storke
    svgImg = svgImg.replace(/#444/ig, 'var(--colorShade1)'); //shade1
    svgImg = svgImg.replace(/#555/ig, 'var(--colorShade2)'); //shade2
    this[iconName] = this.sanitizer.bypassSecurityTrustHtml(svgImg);
    return svgImg;
  };

  private getRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min)
  };

  getPercenatgeLevel(level) {
    let percentage = `${level * 3 / 4} 75`;
    let svgContent = `
    <svg width="250px" height="250px" viewBox="0 0 42 42">
      <path
        d="M21 21 m-11.254 11.254 a 15.91549430918954 15.91549430918954 135 1 1 22.508 0"
        fill="transparent" stroke-linecap="round" style="stroke: #ddd; stroke-width: 2; fill: transparent;position: relative;right: 5px;" />
      <path
        d="M21 21 m-11.254 11.254 a 15.91549430918954 15.91549430918954 135 1 1 22.508 0"
        fill="transparent" stroke-linecap="round"
     style="stroke: var(--primaryColour); stroke-width: 2; fill: transparent; stroke-dasharray: ${percentage}; transition: stroke-dasharray 0.3s;" transition: stroke-dasharray 0.3s;"/>
    </svg>`;
    return this.sanitizer.bypassSecurityTrustHtml(svgContent);
  };


  private createChartForOrders(): void {
    let chart: any = {
      chart: {
        type: 'spline',
        height: 310,
        width: 660,
      },

      title: {
        text: '',
        // text: 'Preisentwicklung',
        // align: 'left',
        // style: {
        //   fontSize: '18px',
        //   textAlign: 'left'
        // }
      },

      credits: {
        enabled: false,
      },

      legend: {
        enabled: false,
      },

      xAxis: {
        // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        // dateTimeLabelFormats: {
        //   millisecond: '%b %e',
        //   second: '%b %e',
        //   minute: '%b %e',
        //   hour: '%b %e',
        //   day: '%b %e',
        //   week: '%b %e',
        //   month: '%b %e',
        //   year: '%b %e'
        // },
        // startOnTick: true,
        // tickInterval: 1,
        type: 'datetime',
        lineWidth: 0.2,
        // endOnTick: false,
        // labels: {
        //   staggerLines: 1,
        //   formatter: function () {
        //     return Highcharts.dateFormat('%b', this.value);
        //   }
        // },
        // tickPositioner: function () {
        //   var positions = [],
        //     tick = Math.floor(this.dataMin),
        //     increment = 1000 * 3600 * 24 * 91.5; // 3 months

        //   for (tick; tick <= this.dataMax; tick += increment) {
        //     positions.push(tick);
        //   }
        //   if (positions.indexOf(this.dataMax) == -1) positions.push(this.dataMax);
        //   return positions;
        // },
        // type: 'datetime',
        // tickInterval: 24 * 3600 * 1000,
      },

      yAxis: {
        title: {
          text: null
        },
        min: 0,
        max: 50,
        tickInterval: 10,
        lineWidth: 0.2,
        startOnTick: true,
        labels: {
          enabled: true,
        },
        gridLineWidth: 1,
      },

      plotOptions: {
        series: {
          point: {
            events: {
              mouseOver: function () {
                this.graphic?.hide();
              },
              mouseOut: function () {
                this.graphic?.hide();
              }
            }
          },

          marker: {
            enabled: false,
          },

          dataLabels: {
            enabled: false,
            overflow: "justify",
          },
          // zones: [
          //   {
          //     value: 40,
          //     color: 'grey',
          //   },
          //   {
          //     color: '#39baea',
          //   },
          // ],
        },
      },

      tooltip: {
        enabled: false,
      },

      series: [
        {
          "type": "spline",
          pointStart: Date.UTC(2019, 0, 1),
          pointIntervalUnit: 'month',
          data: [50, 50, 19, 39.8, 40, 35.7, 26.6, 24.4, 10.6, 15.9],
          "dataLabels": {
            "enabled": false,
            "overflow": "justify"
          },
          showInLegend: false,
          tooltip: {
            valueDecimals: 2
          },
        }
      ],
    };
    let chatLine = Highcharts.chart('chart-orders', chart as any);
  };

  dashboardSPLineChart() {
    let maxCapacity = 8000;
    let chartDashBoard = {
      chart: {
        type: 'spline'
      },

      title: {
        text: 'Bestandsentwicklung',
        align: 'left',
        style: {
          fontSize: '18px',
          textAlign: 'left'
        }
      },

      subtitle: {
        text: 'Max 8.000kg',
        align: 'left',
        style: {
          fontSize: '14px',
          textAlign: 'left',
          color: '#333'
        }
      },

      credits: {
        enabled: false,
      },

      legend: {
        enabled: false,
      },

      xAxis: {
        min: 1,
        categories: ['', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
        accessibility: {
          description: 'Months of the year'
        },
        plotLines: [
          {
            color: 'Gold',
            width: 2,
            value: 7,
            dashStyle: 'Dash',
            label: {
              text: '20 Tage Warnung',
              align: 'right',
              rotation: 0,
            }
          }
        ],
        startOnTick: true,
        minPadding: 0,
      },

      yAxis: {
        title: {
          text: null
        },
        max: maxCapacity * 1.1,
        lineWidth: 1,
        startOnTick: true,
        labels: {
          enabled: false,
        },
        gridLineWidth: 0,
      },

      plotOptions: {
        series: {
          point: {
            events: {
              mouseOver: function () {
                this.graphic?.hide();
              },
              mouseOut: function () {
                this.graphic?.hide();
              }
            }
          },
          marker: {
            enabled: false,
          },

          dataLabels: {
            enabled: false,
            overflow: "justify",
          },
          zones: [
            {
              value: 7300,
              color: 'grey',
            },
            {
              color: '#39baea',
            },
          ],

        },

      },

      tooltip: {
        enabled: false,
      },

      series: [
        {
          "type": "spline",
          marker: {
            enabled: false,
          },
          "data": [
            {
              "y": 8000,
            },
            {
              "y": 7800,
            },
            {
              "y": 7600,
            },
            {
              "y": 7300,
              marker: {
                enabled: true,
                symbol: 'circle',
                fillColor: 'red',
                radius: 5
              }
            },
            {
              "y": 7000,
            },
            {
              "y": 6500,
            },
            {
              "y": 6000,
            },
            {
              "y": 5700,
            },
            {
              "y": 3400,
            },
            {
              "y": 100,
            },
          ],
          "dataLabels": {
            "enabled": false,
            "overflow": "justify"
          },
          showInLegend: false,
        },
      ],
    };
    let chatDashBoard = Highcharts.chart('newDashBoard', chartDashBoard as any);
  };

  getAddresses(): void {
    this.adminService.getPortaladdresses().subscribe((result: any) => {
      if (result.success) {
        this.processAddressData(result.data);
      }
    });
  }

  processAddressData(addressData: any[]): void {
    if (addressData && addressData.length > 0) {
      const primaryHeatingAddress = addressData.find(address => address.isPrimary && address.myheating?.isHeating);
      if (primaryHeatingAddress) {
        this.isPrimary = primaryHeatingAddress.isPrimary;
        this.isHeating = primaryHeatingAddress.myheating.isHeating;
      } else {
        this.isPrimary = true;
        this.isHeating = false;
      }
    }
  }

  getDashboardData() {
    this.adminService.getDashboardData().subscribe((result: any) => {
      if (result.success) {
        this.dashboardData = result.data;
      }
    })
  };

  getLatestOrders() {
    this.adminService.getLatestOrders().subscribe((result: any) => {
      if (result.success) {
        this.orders = result.data;
      };
    });
  };

  allOrders() {
    this.showAllOrders.emit('Bestellung')
  };

  async calculatePrice() {
    let params = new URLSearchParams(this.url);
    let sessionId = decodeURIComponent(params.get("sid"));
    let sessionExists = await this.sampleService.validateSessionData(sessionId)
    if (!sessionExists) {
      let obj = { primaryAdress: this.address, loginUser: true, selectedProduct: {} };
      this.newBigPc.createNewSession(obj)
    } else {
      this.showPricecalcFromPrimayAddress.emit(this.address);
    };
  };

  getPrimaryAddress() {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await this.adminService.getPrimaryAddress().toPromise();
        if (result.success) {
          this.address = result.data;
          this.checkAndCreateNewSession();
        } else {
          let newsessionId = this.sampleService.genereateNewSessionId();
          this.setupUrl(newsessionId);
        };
        resolve(true);
      } catch (err) {
        reject(err);
      };
    });
  };

  async checkAndCreateNewSession() {
    let params = new URLSearchParams(this.url);
    let sessionId = decodeURIComponent(params.get("sid"));
    let sessionExists = await this.sampleService.validateSessionData(sessionId);
    if (!sessionExists) {
      let obj = { primaryAdress: this.address, loginUser: true, selectedProduct: {} }
      this.newBigPc.createNewSession(obj)
    };
  };

  setupUrl(newSessionId) {
    let newUrl;
    let domains = JSON.parse(localStorage.getItem("domainUrls"));
    if (environment.testSite_or_thirdPartySites) {
      newUrl = `https://${domains.priceCalcUrl}#&view=bigpc&sid=${newSessionId}&page=Dashboard`
    } else {
      newUrl = `${environment.pricecalc}#&Domainname=${domains.domainName}&view=bigpc&sid=${newSessionId}&page=Dashboard`
    };

    localStorage.setItem('sid', newSessionId);
    let newSession = {
      priceCalcUrl: domains.priceCalcUrl,
      domainName: domains.domainName,
      quantity: 0,
      stations: 0,
      product: {
        "_id": "",
        "name": ""
      },
      postalCode: { plzId: '', city: '', postalcode: '', suburb: '' },
      localOptions: [
        { optionSetId: '', optionId: '', sort: 1 },
        { optionSetId: '', optionId: '', sort: 2 },
        { optionSetId: '', optionId: '', sort: 3 },
        { optionSetId: '', optionId: '', sort: 4 },
        { optionSetId: '', optionId: '', sort: 5 }
      ],
      ratePaymentSettings: { status: false },
      desiredDateSettings: {},
      cart: [
        { _id: '' }
      ],
      co2Compensation: [],
    }
    let setSessionData = JSON.stringify(newSession)
    localStorage.setItem(newSessionId, setSessionData)
    window.history.pushState('', document.title, newUrl);
  };

  openFilling() {
    console.log("2125");
    let meineopen = this.dialog.open(MeinepopupComponent, {
      data: {
        width: '550px',
        type: 'tankFill'
      }
    });

    meineopen.afterClosed().subscribe(async (data: any) => {
      if (data) {
        console.log(data);

      };
    });
  };

  openBestands() {
    console.log("123");
    let meineopen = this.dialog.open(MeinepopupComponent, {
      data: {
        width: '550px',
        type: 'Bestandskontrolle'
      }
    });
    meineopen.afterClosed().subscribe(async (data: any) => {
      if (data) {
        console.log(data);
      }
    })
  };

  getSvg(type) {
    switch (type) {
      case 1: return this['Fire'];
      case 2: return this['Pellets'];
      case 3: return this['Gas'];
    };
  };

  returnSvgs(svg) {
    return this[svg];
  };

};
