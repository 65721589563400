<div class="aupris_main scrolltotopprogress" *ngIf="!addAddressinDashboard">
    <div class="au-container section-container probar">

        <div class="card card2" style="text-align: center;text-align: -webkit-center;">
            <div class="aurow">
                <div class="aucol-lg-7 aucol-md-6 aucol-sm-6 aucol-xs-12 textleft">
                    <div [ngStyle]="{color: 'var(--primaryColour)'}" class="headerr fontColor">Willkommen zu Ihrem
                        online
                        Kundenkonto!
                    </div>
                    <div class="fontColor">Bei Fragen oder Problemen wenden Sie sich bitte mit Ihren
                    </div>
                    <div class="mb15 fontColor">Kundendaten an unseren Support.
                    </div>
                    <div style="margin-left: -15px;">
                        <div style="position: relative;">
                            <button class="au-btn btn-default btncls1 mt25" style="float:left"
                        [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                        *ngIf="address" (click)="openBestands()">{{'Bestandskontrolle'}}</button>
                    </div>
                        <table>
                            <tr class="mb15 fontColor">
                                <td class="alignText" style="padding-bottom: 15px;">Online Konto:</td>
                                <td style="padding-bottom: 15px;">AP{{onlineKonto}}</td>
                            </tr>
                            <tr class="fontColor" *ngIf="dashboardData?.telefon">
                                <td class="alignText">Telefon:</td>
                                <td>{{dashboardData?.telefon}}</td>
                            </tr>
                            <tr class="fontColor" *ngIf="dashboardData?.email">
                                <td class="alignText">Email:</td>
                                <td>{{dashboardData?.email}}</td>
                            </tr>
                            <tr class="fontColor" *ngIf="dashboardData?.supportTimes">
                                <td class="alignText">Support Zeit:</td>
                                <td>{{dashboardData?.supportTimes}}</td>
                            </tr>
                        </table>
                        <button class="au-btn btn-default btncls1 mt25" style="float:right"
                        [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                        *ngIf="address" (click)="openFilling()">{{'FillupTank'}}</button>
                        <!-- <button class="au-btn btn-default btncls1 mt25" style="float:right"
                        [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                        *ngIf="address" (click)="openSecoendStage()">{{'2nd stage'}}</button> -->
                    </div>
                </div>

                <div class="aucol-lg-5 aucol-md-6 aucol-sm-6 aucol-xs-12">
                    <span [innerHTML]="dashboarduser"></span>
                </div>
            </div>
        </div>
        <div class="sectionmt">
            <div class="aurow" style="width: 100%;margin-left: 0px;">
                <div class="aucol-lg-4 aucol-md-4 aucol-sm-4 aucol-xs-12 box1pd">
                    <div class="card cardcss">
                        <div *ngIf="address">
                            <div [ngStyle]="{color: 'var(--primaryColour)'}" class="headercss mb15">
                                {{languageData['Order_info']['Var_primaryAddress'] || 'Primärlieferadresse'}}</div>

                            <div><b>{{address?.currentTitle}} {{address?.firstName}} {{address?.lastName}}</b></div>
                            <div> {{address?.streetName}}<span *ngIf="address?.houseNo"> {{address?.houseNo}}
                                </span>
                            </div>
                            <div>{{address?.zipCode}} {{address?.city}}</div>
                            <div *ngIf="address?.country">{{address?.country}}</div>

                        </div>
                        <button class="au-btn btn-default btncls1 mt25" style="float:right"
                            [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                            *ngIf="address" (click)="calculatePrice()">{{languageData['Small-pc'].Var_Preis_Berechnen ||
                            'Preis
                            Berechnen'}}</button>
                        <button class="au-btn btn-default btncls1 mt25" style="float:right"
                            [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                            *ngIf="!address"
                            (click)="createAnAddress()">{{languageData['Order_info']['Var_createAddress'] || 'Adresse
                            anlegen'}}</button>
                    </div>
                </div>
                <div class="aucol-lg-8 aucol-md-8 aucol-sm-8 aucol-xs-12 box2pd">
                    <div class="card cardcss">
                        <div [ngStyle]="{color: 'var(--primaryColour)'}" class="headercss mb15">
                            {{languageData['Order_info']['Var_latestOrders'] || 'Letzte Bestellungen'}}</div>

                        <div class="aurow" *ngFor="let order of orders">
                            <div class="aucol-lg-3 aucol-md-3 aucol-sm-3 aucol-xs-12">
                                {{order.date | date : "dd.MM.yyyy"}}
                            </div>
                            <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-12">
                                {{order.produktName}}
                            </div>
                            <div class="aucol-lg-3 aucol-md-3 aucol-sm-3 aucol-xs-12">
                                {{order.amount |replaced}} <span>{{order.units}}
                                </span>
                            </div>
                        </div>
                        <button class="au-btn btn-default btncls2 mt25" style="float:right"
                            [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                            (click)="allOrders()">{{languageData['Order_info']['Var_allOrders'] ||
                            'Bestellübersicht'}}</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <loader [full-screen]="true" *ngIf="loader" [color]="'var(--primaryColour)'"></loader>
</div>

<app-pc-create-address *ngIf="addAddressinDashboard" (abort)="cancelEdit()">
</app-pc-create-address>