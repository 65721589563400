<section style="min-width: 400px;">
    <div class="aupris_main">
        <div class="text-center">
            <label for="image" class="tippp"
                style="font-weight:500;margin-top:0px !important;margin-bottom: 14px;display: inline-block;">
                <input type="file" name="image" id="image" style="display:none;" (change)="fileChangeEvent($event)"
                    accept=".png, .jpeg, .jpg" />
                <span style="padding-right:10px; height:20px;cursor:pointer;vertical-align: middle;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.693" height="15.693" viewBox="0 0 15.693 15.693">
                        <path id="ic_add_circle_outline_24px"
                            d="M10.631,5.923H9.062V9.062H5.923v1.569H9.062V13.77h1.569V10.631H13.77V9.062H10.631ZM9.847,2a7.847,7.847,0,1,0,7.847,7.847A7.85,7.85,0,0,0,9.847,2Zm0,14.124a6.277,6.277,0,1,1,6.277-6.277A6.286,6.286,0,0,1,9.847,16.124Z"
                            transform="translate(-2 -2)" fill="#707070" />
                    </svg>
                </span>
                <span class="upload pointer" (click)=save()>
                    {{languageData['Hamburger'].Var_Laden_sie || 'Laden Sie ein neues Foto hoch'}}
                </span>
            </label>
        </div>

        <image-cropper style="height: 425px;
        overflow-y: auto;" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [roundCropper]="false"
            [aspectRatio]="1 / 1" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
        </image-cropper>

        <div>
            <button type="button" class="cnlBtn smalBtn au-btn"
                [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)', outline: 'none'}"
                [style.background]="'var(--primaryColour)'" (click)="cancel()">
                {{languageData['Address'].Var_Abbrechen || 'Abbrechen'}}
            </button>

            <button type="button" class="cnlBtn smalBtn au-btn" style="margin-left: 10px"
                [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                [style.background]="'var(--primaryColour)'" *ngIf="data && data !=''" (click)="deleteImage()">
                {{languageData['Address'].Var_Loschen || 'Löschen'}}
            </button>

            <button type="button" class="cnlBtn smalBtn au-btn pull-right"
                [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                [style.background]="'var(--primaryColour)'" [disabled]="!croppedImage" (click)="saveImage()">
                {{languageData['Hamburger'].Var_save || 'Speichern'}}
            </button>
        </div>
    </div>
</section>