<!----------------------------------------Dashboard With My Heating  ------------------------------------------------------->
<div class="aupris_main scrolltotopprogress" *ngIf="isPrimary && isHeating">
    <div class="au-container section-container probar">
        <div class="card" style="text-align: center; text-align: -webkit-center;height: 40%;">
            <div class="aurow">
                <div class="aucol-lg-4 aucol-md-4 aucol-sm-4 aucol-xs-4 textleft" style="margin-bottom: -120px;">
                    <div style="margin-left: 40px;">
                        <div class="internalText">
                            <span>
                                {{percentageLevelData.activePercentageLevel}}%
                                <br>
                                <span style="position: relative;right: 5px; top: 5px;"
                                    [innerHTML]="getSvg(percentageLevelData.productId)">
                                </span>
                                <span style="padding: 5px 0; display: inline-block;">
                                    {{percentageLevelData.currentActiveLevel | replaced}} kg
                                </span>
                                <br>
                                Reichweite : {{percentageLevelData.remainingDays}} Tage
                            </span>
                        </div>
                        <div [innerHTML]="getPercenatgeLevel(percentageLevelData.activePercentageLevel)"></div>
                        <div
                            [ngClass]="(!percentageLevelData?.hasWarning1 && percentageLevelData?.hasWarning2) ? 'setSingle' : ''">
                            <div class="one" *ngIf="percentageLevelData.hasWarning1">
                                1
                            </div>
                            <div class="two" *ngIf="percentageLevelData.hasWarning2">
                                2
                            </div>
                        </div>
                        <div class="setDates">
                            <span class="date-left">
                                {{percentageLevelData.currentDate | date: 'dd.MM.yy' }}
                            </span>
                            <span class="maxValue-right">
                                Max. {{percentageLevelData.maxTankSize | replaced }}kg
                            </span>
                        </div>
                    </div>
                    <div class="aurow" style="display: flex;margin-left: 35px;"
                        [ngClass]="(percentageLevelData?.hasWarning1 && !percentageLevelData?.hasWarning2) ? 'hasActive' : !percentageLevelData?.hasWarning1 && !percentageLevelData?.hasWarning2 ? 'notActive' : 'svgicons'">
                        <div style="text-align: center;width: 30%;position: relative;right: 15px;">
                            <span [innerHTML]="returnSvgs('befullung')" (click)="openFilling()"
                                style="cursor: pointer;"></span>
                            <strong>Befüllung</strong>
                        </div>
                        <div style="width: 30%;text-align: left;margin-left: 7px;">
                            <span [innerHTML]="returnSvgs('Bestandskontrolle')" (click)="openBestands()"
                                style="cursor: pointer;">
                            </span>
                            <strong>Bestandskontrolle</strong>
                        </div>
                    </div>
                    <div class="aurow" style="display: flex;margin-left: 35px;"
                        [ngClass]="(percentageLevelData?.hasWarning1 && !percentageLevelData?.hasWarning2) ? 'rowactiveSvgs' : !percentageLevelData?.hasWarning1 && !percentageLevelData?.hasWarning2 ? 'notActiverow' : 'rowsvgs'">

                        <div style="text-align: center;width: 30%;position: relative;right: 15px;">
                            <span [innerHTML]="returnSvgs('preisrechner')"></span>
                            <strong>Preisrechner</strong>
                        </div>
                        <div style="width: 25%;text-align: left;margin-left: 7px;">
                            <span [innerHTML]="returnSvgs('MeineHeizung')"> </span>
                            <strong>Meine Heizung</strong>
                        </div>
                    </div>
                </div>

                <div class="aucol-lg-8 aucol-md-8 aucol-sm-8 aucol-xs-8 textleft" style="margin-bottom: -70px;">
                    <div class="aurow">
                        <div class="aucol-lg-5 aucol-md-6 aucol-sm-6 aucol-xs-12 textleft">
                            <div *ngIf="address" class="addressDiv">
                                <div>
                                    <b>
                                        {{address?.currentTitle}}
                                        {{address?.firstName}}
                                        {{address?.lastName}}
                                    </b>
                                </div>
                                <div>
                                    {{address?.companyName}}
                                    {{address?.addressZusatz}}
                                </div>
                                <div>
                                    {{address?.streetName}}
                                    {{address?.houseNo}}
                                </div>
                                <div>
                                    {{address?.zipCode}}
                                    {{address?.city}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="aurow">
                        <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12 textleft">

                            <div style="height: 295px;width: 660px;" id="newDashBoard"></div>
                            <!-- <div id="chart-line"></div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="display: grid;grid-template-columns: 1fr 1fr 1fr;">
            <div class="textleft card" style="padding: 23px 0px 0px 23px;width: 98%;height: 50%;overflow-x: hidden;">
                <h4 style="margin-top: -10px;margin-left: -15px;color: var(--primaryColour) !important;">News-Feed</h4>
                <div *ngFor="let content of newsData">
                    <div class="aurow" style="margin-bottom: 25px;">

                        <div class="textleft" style="margin-top: 14px">
                            <img width="90px" height="50px" [src]="content.imgUrl">
                        </div>
                        <div class="textleft">
                            <div>
                                {{content.date | date: 'dd.MM.yy' }}
                            </div>
                            <div>
                                {{content.content}}
                                {{content.content}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" style="width: 98%;height: 30%;padding: 10px;">
                <h4 style="margin-top: 2px;color: var(--primaryColour)!important;">Kontakt</h4>
                <div>Bei Fragen oder Problemen wenden Sie sich bitte mit Ihren Kundendaten an unseren Support.</div>
                <div style="margin-left: -15px;">
                    <table>
                        <tr class="mb15 fontColor">
                            <td class="alignText" style="padding-bottom: 15px;">Online Konto:</td>
                            <td style="padding-bottom: 15px;">AP{{onlineKonto}}</td>
                        </tr>
                        <tr class="fontColor" *ngIf="dashboardData?.telefon">
                            <td class="alignText">Telefon:</td>
                            <td>{{dashboardData?.telefon}}</td>
                        </tr>
                        <tr class="fontColor" *ngIf="dashboardData?.email">
                            <td class="alignText">Email:</td>
                            <td>{{dashboardData?.email}}</td>
                        </tr>
                        <tr class="fontColor" *ngIf="dashboardData?.supportTimes">
                            <td class="alignText">Support Zeit:</td>
                            <td>{{dashboardData?.supportTimes}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="card" style="width: 100%;height: 30%;padding: 10px;">
                <h4 style="margin-top: 2px;color: var(--primaryColour)!important;">Letzte Bestellungen</h4>
                <div class="aurow" *ngFor="let order of orders">
                    <div class="aucol-lg-3 aucol-md-3 aucol-sm-3 aucol-xs-12">
                        {{order.date | date : "dd.MM.yyyy"}}
                    </div>
                    <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-12">
                        {{order.produktName}}
                    </div>
                    <div class="aucol-lg-3 aucol-md-3 aucol-sm-3 aucol-xs-12">
                        {{order.amount |replaced}} <span>{{order.units}}
                        </span>
                    </div>
                </div>
                <button class="au-btn btn-default" style="float:right;position: relative;top: 50px;left: 0px;"
                    [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                    (click)="allOrders()">{{languageData['Order_info']['Var_allOrders'] ||
                    'Bestellübersicht'}}</button>
            </div>
        </div>
    </div>
</div>

<!----------------------------------------Dashboard Without My Heating  ------------------------------------------------------->

<div class="widthh aupris_main au-container section-container aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12"
    *ngIf="isPrimary && !isHeating" style="padding: 0px;">
    <div class="card aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12">
        <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-6" style="padding: 0px;">
            <div>
                <h4 style="color: var(--primaryColour)!important;margin-top: 20px;margin-bottom: 25px;">Willkommen Zu
                    Ihrem online Kundenkonto!</h4>
                <div>Bei Fragen oder Problemen wenden Sie sich bitte mit Ihren</div>
                <div style="margin-bottom: 10px;">Kundendaten an unseren Support.</div>
                <div style="margin-left: -15px;">
                    <table>
                        <tr class="mb15 fontColor">
                            <td class="alignText" style="padding-bottom: 15px;">Online Konto:</td>
                            <td style="padding-bottom: 15px;">AP{{onlineKonto}}</td>
                        </tr>
                        <tr class="fontColor" *ngIf="dashboardData?.telefon">
                            <td class="alignText">Telefon:</td>
                            <td>{{dashboardData?.telefon}}</td>
                        </tr>
                        <tr class="fontColor" *ngIf="dashboardData?.email">
                            <td class="alignText">Email:</td>
                            <td>{{dashboardData?.email}}</td>
                        </tr>
                        <tr class="fontColor" *ngIf="dashboardData?.supportTimes">
                            <td class="alignText">Support Zeit:</td>
                            <td>{{dashboardData?.supportTimes}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-6">
            <span [innerHTML]="returnSvgs('dashboarduser')" class="svgalign"></span>
        </div>
    </div>
    <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12" style="padding: 0;">
        <div class="aurow" style="width: 100%;margin-left: 0px;">
            <div class="aucol-lg-4 aucol-md-4 aucol-sm-4 aucol-xs-4 box1pd">
                <div class="card cardcss" style="padding: 14px;">
                    <div *ngIf="address">
                        <div [ngStyle]="{color: 'var(--primaryColour)'}" class="headercss mb15">
                            {{languageData['Order_info']['Var_primaryAddress'] || 'Primärlieferadresse'}}</div>

                        <div><b>{{address?.currentTitle}} {{address?.firstName}} {{address?.lastName}}</b></div>
                        <div> {{address?.streetName}}<span *ngIf="address?.houseNo"> {{address?.houseNo}}
                            </span>
                        </div>
                        <div>{{address?.zipCode}} {{address?.city}}</div>
                        <div *ngIf="address?.country">{{address?.country}}</div>

                    </div>
                    <button class="au-btn btn-default btncls1 mt25" style="float:right"
                        [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                        *ngIf="address" (click)="calculatePrice()">{{languageData['Small-pc'].Var_Preis_Berechnen ||
                        'Preis
                        Berechnen'}}</button>
                    <button class="au-btn btn-default btncls1 mt25" style="float:right"
                        [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                        *ngIf="!address" (click)="createAnAddress()">{{languageData['Order_info']['Var_createAddress']
                        || 'Adresse
                        anlegen'}}</button>
                </div>
            </div>
            <div class="aucol-lg-8 aucol-md-8 aucol-sm-8 aucol-xs-8 box2pd" style="padding-left: 0px;">
                <div class="card cardcss" style="padding: 14px;">
                    <div [ngStyle]="{color: 'var(--primaryColour)'}" class="headercss mb15">
                        {{languageData['Order_info']['Var_latestOrders'] || 'Letzte Bestellungen'}}</div>

                    <div class="aurow" *ngFor="let order of orders">
                        <div class="aucol-lg-3 aucol-md-3 aucol-sm-3 aucol-xs-12">
                            {{order.date | date : "dd.MM.yyyy"}}
                        </div>
                        <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-12">
                            {{order.produktName}}
                        </div>
                        <div class="aucol-lg-3 aucol-md-3 aucol-sm-3 aucol-xs-12">
                            {{order.amount |replaced}} <span>{{order.units}}
                            </span>
                        </div>
                    </div>
                    <button class="au-btn btn-default btncls2 mt25" style="float:right"
                        [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                        (click)="allOrders()">{{languageData['Order_info']['Var_allOrders'] ||
                        'Bestellübersicht'}}</button>

                </div>
            </div>
        </div>
    </div>
</div>


<!-- ----------------------------------------------------- -->
<!-- <div class="aucol-lg-1 aucol-md-1 aucol-sm-1 aucol-xs-1">
                </div>

                <div class="aucol-lg-8 aucol-md-8 aucol-sm-12 aucol-xs-12 textleft">
                    <div class="aurow" style="margin-bottom: 21px;">
                        <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-12 textleft">
                            <div class="Bestand">
                                <strong> Preisentwicklung</strong>
                            </div>
                        </div>

                        <div class="aucol-lg-6 aucol-md-6 aucol-sm-6 aucol-xs-12 textleft">
                            <div>
                                <select class="selectTag">
                                    <option>Heizol</option>
                                    <option>Pellets</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="aurow">
                        <div id="chart-orders"></div>
                    </div>

                    <div class="aurow" style="display: flex; margin-left: 5px; margin-top: 14px;">
                        <div class="cardsSort">
                            Jahr
                        </div>
                        <div class="cardsSort">
                            Monat
                        </div>
                        <div class="cardsSort">
                            Woche
                        </div>
                    </div>
                </div> -->