
<div style="width: 500px;" *ngIf="data.type=='meinepopup' && !adressenpopup && !data.address?.show">
    <p class="header">
        {{languageData.VarKundeportalDashboard1 || 'Meine Heizung'}}
    </p>

    <p class="listyle" style="margin-bottom: 10px;">
        {{languageData.VarKundeportalMeine_Heizung2 || 'Konfigurieren Sie Ihre Heizung und profitieren Sie von den
        Vorteilen:'}}
    </p>

    <div style="margin-left: 30px">
        <li class="listyle">
            {{languageData.VarKundeportalMeine_Heizung3 || 'Füllstandskontrolle'}}
        </li>
        <li class="listyle">
            {{languageData.VarKundeportalMeine_Heizung4 || 'Meldungen bei niedrigem Füllstand'}}
        </li>
        <li class="listyle">
            {{languageData.VarKundeportalMeine_Heizung5 || 'Anzeige Restreichweite des Bestands'}}
        </li>
        <li class="listyle">
            {{languageData.VarKundeportalMeine_Heizung6 || 'Messung des Jahresverbrauchs'}}
        </li>
        <li class="listyle" style="margin-bottom: -5px;">
            {{languageData.VarKundeportalMeine_Heizung7 || 'Erinnerung Heizungwartung'}}
        </li>
    </div>
    <div style="margin-top: 25px;">
        <button class="popbtn cancel-btn" (click)="close()" style="outline:none"> {{languageData.Var_cancel || 'Abbrechen'}} </button>
        <button [ngStyle]="{background: 'var(--primaryColour)'}"
            class="popbtn pull-right" (click)="next()"> {{languageData.Var_Weiter || 'Weiter'}}</button>
    </div>
</div>

<!-- ..............................Addressen Popup.................................. -->

<div *ngIf="adressenpopup">
    <h2 class="header1"> Adresse auswählen </h2>
    <div [ngClass]="{'cards1': address.length === 1, 'cards': address.length > 1}">
        <div *ngFor="let addresen of address" class="cardssss" (click)="initalizeProcess(addresen)">
            <div><b>{{addresen?.currentTitle}} {{addresen?.firstName}} {{addresen?.lastName}}</b></div>
            <div> {{addresen?.streetName}}<span *ngIf="addresen?.houseNo"> {{addresen?.houseNo}}
                </span>
            </div>
            <div>{{addresen?.zipCode}} {{addresen?.city}}</div>
            <div *ngIf="addresen?.country">{{addresen?.country}}</div>
        </div>
    </div>

    <div style="border: 1px solid #ddd;margin: 23px 0px;"></div>

    <div style="text-align: center;">
        <button class="btn" (click)="changeView('Adressen')">Neue Adresse hinzufügen</button>
    </div>
</div>

<!------------------------------------ Tank refill popup ------------------------------------------------------------->


<div style="width: 500px;" *ngIf="data.type=='tankFill'">
    <div class="tankFillHeader">
        {{languageData.Dashboard.VarKundeportalDashboard4 || 'Filling Up'}}
    </div>
    <div [formGroup]="tankFillForm" style="margin-left:87px;">
        <div class="textfill">
            <label style="position: relative;bottom: 5px;" for="tankFullCheckbox" class="input-label">
                Tank Full?
            </label>
            <input type="checkbox" formControlName="tankFull">
        </div>
        <div class="textfill">
            <label for="newFuelLevel" class="input-label">
                {{ languageData.Dashboard.VarKundeportalDashboard6 || 'New fuellevel'}}
            </label>
            <input type="text" formControlName="newFuelLevel"
                (input)="validateInput(tankFillForm.controls.newFuelLevel.value , 'newFuelLevel')">
                <span class="units"> {{'kg/l'}} </span>
            <div *ngIf="tankFillForm.controls.newFuelLevel.touched && tankFillForm.controls.newFuelLevel.status == 'INVALID' && !tankFillForm.get('newFuelLevel').hasError('maxFuelAmount') && !tankFillForm.get('newFuelLevel').hasError('lessThanInput1') && !tankFillForm.get('newFuelLevel').hasError('pattern')"
                class="refill-alert">
                {{'Pflichtfeld ausfüllen'}}!
            </div>
            <div *ngIf="tankFillForm.get('newFuelLevel').hasError('pattern') && tankFillForm.get('newFuelLevel').touched"
                class="refill-alert">
                {{languageData['Erro_msg'].VarKundeportalErro_msg2 || 'Ungültige Eingabe, nur Ganze Zahlen erlaubt'}}!
            </div>
            <div *ngIf="showInputError" class="refill-alert">
                {{languageData['Erro_msg'].VarKundeportalErro_msg1 || 'Eingabe muss größer als null sein'}}!
            </div>
            <div *ngIf="tankFillForm.get('newFuelLevel').hasError('lessThanInput1') && tankFillForm.get('newFuelLevel').touched" class="refill-alert">
                {{languageData['Erro_msg'].VarKundeportalErro_msg3 || 'Füllstand muss größer als getankte Menge sein!'}}
            </div>
            <div *ngIf="tankFillForm.get('newFuelLevel').hasError('maxFuelAmount') && tankFillForm.get('newFuelLevel').touched" class="refill-alert">
                {{languageData['Erro_msg'].VarKundeportalErro_msg4 || 'Füllstand muss größer als getankte Menge sein!'}}
            </div>
        </div>

        <div class="textfill">
            <label for="AddedFuelAmount" class="input-label">
                {{ languageData.Dashboard.VarKundeportalDashboard11 ||'Added fuelamount'}}
            </label>
            <input type="text" formControlName="AddedFuelAmount"
                (input)="validateInput(tankFillForm.controls.AddedFuelAmount.value , 'AddedFuelAmount')">
                <span class="units">    {{'kg/l'}} </span>
            <div *ngIf="tankFillForm.controls.AddedFuelAmount.touched && tankFillForm.controls.AddedFuelAmount.status == 'INVALID' && !tankFillForm.get('AddedFuelAmount').hasError('maxFuelAmount') && !tankFillForm.get('AddedFuelAmount').hasError('lessThanInput1')&& !tankFillForm.get('AddedFuelAmount').hasError('pattern')"
                class="refill-alert">
                {{'Pflichtfeld ausfüllen'}}!
            </div>
            <div *ngIf="tankFillForm.get('AddedFuelAmount').hasError('pattern') && tankFillForm.get('AddedFuelAmount').touched"
                class="refill-alert">
                {{languageData['Erro_msg'].VarKundeportalErro_msg2 || 'Ungültige Eingabe, nur Ganze Zahlen erlaubt'}}!
            </div>
            <div *ngIf="showInputError" class="refill-alert">
                {{languageData['Erro_msg'].VarKundeportalErro_msg1 || 'Eingabe muss größer als null sein!'}}!.
            </div>
            <div *ngIf="tankFillForm.get('AddedFuelAmount').hasError('lessThanInput1') && tankFillForm.get('AddedFuelAmount').touched" class="refill-alert">
                {{languageData['Erro_msg'].VarKundeportalErro_msg3 || 'Eingabe muss größer als null sein!'}}
            </div>
            <div *ngIf="tankFillForm.get('AddedFuelAmount').hasError('maxFuelAmount') && tankFillForm.get('AddedFuelAmount').touched" class="refill-alert">
                {{languageData['Erro_msg'].VarKundeportalErro_msg4 || 'Füllstand muss größer als getankte Menge sein!'}}
            </div>
        </div>

        <div class="textfill">
            <label for="date" class="input-label">{{'Date'}}</label>
            <input type="text" [matDatepicker]="picker" (click)="picker.open()" focus="picker.open()" [max]="minDate"
                formControlName="date">
            <mat-datepicker #picker startView="multi-year">
            </mat-datepicker>
        </div>
    </div>
    <div style="margin-top: 20px;">
        <button class="popbtn" (click)="backToDashboard()" style="background-color: #ddd;"> {{'Abbrechen'}} </button>
        <button [ngStyle]="{}" [ngClass]="{'disabled': !tankFillForm.valid}" class="popbtn saveBtn"
            [disabled]="!tankFillForm.valid" (click)="saveFillingData()">{{'Speichern'}}</button>
    </div>
</div>

<!------------------------------------ Bestandskontrolle/ Inventry Controll popup------------------------------------------------------------>
<div style="width: 500px;" *ngIf="data.type=='Bestandskontrolle'">
    <div class="tankFillHeader">
        {{languageData.Dashboard.VarKundeportalDashboard5 || 'Inventory Check-up'}}
    </div>
    <div [formGroup]="bestandForm" style="margin-left:87px;">
        <div class="textfill">
            <label for="CurrentFuelLevel" class="input-label">
                {{ languageData.Dashboard.VarKundeportalDashboard7 || 'Current Level'}}:
            </label>
            <input type="text" class="inputtext" formControlName="currentFuelLevel" [disabled]="!isEditing" (blur)="saveData()"
                (input)="bestandInputValidate(bestandForm.controls.currentFuelLevel.value , 'currentFuelLevel')">
                <span class="pencilalign material-icons" [innerHTML]="EditIcon" (click)="enableEditing()"> 
                    </span>      
            <div *ngIf="bestandForm.controls.currentFuelLevel.touched && bestandForm.controls.currentFuelLevel.status == 'INVALID' && !bestandForm.get('currentFuelLevel').hasError('maxFuelAmount') && !bestandForm.get('currentFuelLevel').hasError('lessThanInput1') && !bestandForm.get('currentFuelLevel').hasError('pattern')"
                class="refill-alert">
                {{'Pflichtfeld ausfüllen'}}!
            </div>
            <div *ngIf="bestandForm.get('currentFuelLevel').hasError('maxFuelAmount') && bestandForm.get('currentFuelLevel').touched"
                class="refill-alert">
                {{languageData['Erro_msg'].VarKundeportalErro_msg6 || 'Bestand kann nicht größer wie maximale Tankkapazität sein!'}}
            </div>
            <div *ngIf="showInputError" class="refill-alert">
                {{languageData['Erro_msg'].VarKundeportalErro_msg5 || 'Bestand kann nicht negativ sein!'}}  
            </div>
        </div>
        <div class="textfill">
            <label for="date" class="input-label">{{'Date'}}</label>
            <input type="text" class="inputtext" [matDatepicker]="picker" (click)="picker.open()" focus="picker.open()" [max]="minDate"
                formControlName="date">
            <mat-datepicker #picker startView="multi-year">
            </mat-datepicker>
        </div>
    </div>
    <div style="margin-top: 20px;">
        <button class="popbtn" (click)="backToDashboard()" style="background-color: #ddd;">
            {{languageData.Var_cancel || 'Abbrechen'}}  
        </button>
        <button [ngStyle]="{}" [ngClass]="{'disabled': !bestandForm.valid}" class="popbtn saveBtn"
            [disabled]="!bestandForm.valid" (click)="saveInventarydata()">
            {{'Speichern'}}
        </button>
    </div>
</div>

<!------------------------------------------- hyperlink-->

<div style="width: 500px;" *ngIf="data.type=='hyperlink'">
    <p class="hyperlinkheader">
        {{languageData.Meine_Heizung.VarKundeportalMeine_Heizung38 || 'Hinweis zum Normverbrauch, Bestandstracking und Verbrauchsprognose'}}
    </p>
    <p class="hyperlink" (click)="closehyperlink()"> {{'X'}} </p>

    <div class="listyle prognose">
        {{languageData.Meine_Heizung.VarKundeportalMeine_Heizung39 || 'Bitte beachten sie das es sich bei unserer Prognose nur um Schätzungen handelt. Die Genauigkeit unserer Berechnungen hängt von der Genauigkeit ihrer Angaben ab. Wir haften nicht für mögliche auftretende Probleme. Die Prognose ersetzt nicht die eigene Kontrolle, kontrollieren sie regelmäßig ihren Stand und tragen diesen nach.'}}
    </div>

</div>



<div id="snackbar" #snackbarElement>
    <span id="snackbar-message"></span>
</div>
  
<!-- ----------------------------------My heating Popup for No Products -------------------------------------------- -->

<div style="width: 500px;" *ngIf="data.type == 'noProducts'">
    <h4 class="headerp">{{languageData.VarKundeportalDashboard1 || 'Meine Heizung'}}</h4>
    <div class="infoicn"><img
            style="transform: rotate(180deg);width: 100px;"
            src="assets/Trackingofinventoriessvgs/infoicon-inventary.svg">
    </div>
    <div class="producttxt">
        {{languageData.VarKundeportalMeine_Heizung41 || 'Es sind keine Produkte für die Bestandskontrolle verfügbar.'}}
    </div>
    <div style="text-align: center;"><button class="closebtn" (click)="close()">{{languageData.VarKundeportalMeine_Heizung42 || 'Schließen'}}</button></div>
</div>

<loader [full-screen]="true" *ngIf="showFullScreenLoading" [color]="'var(--primaryColour)'"></loader>