import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-meine-progress',
  templateUrl: './meine-progress.component.html',
  styleUrls: ['./meine-progress.component.css']
})
export class MeineProgressComponent implements OnInit {

  @Input() currentStage: number = 4; // This can be dynamically set
  totalStages = 4;
  get stages(): number[] {
    return [1, 2, 3, 4];
  }


  constructor() { }

  ngOnInit(): void {
    console.log("current stage", this.currentStage);
  }

  cssClassNames(stage: number): string {
    switch (this.currentStage) {
      case 1:
        return "hide1 show1 grid-item emptyColor";
      case 2:
        return "hide23 show2 grid-item";
      case 3:
        return "hide3 show3 grid-item";
      case 4:
        return "hide4 show4 grid-item";
    }
  }


}
