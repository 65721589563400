<div *ngIf='!showPayments'class="aupris_main scroller my-full-screen-dialog">
  <pre [innerHtml]="infotext" style="border: none;background-color: white;font-family: sans-serif;">
  </pre>
  <button class="au-btn btn-default" [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
    (click)="onclick()" style="float:right;">
    {{languageData['Popups']['Var_Schlieben']|| 'Schließen' }}</button>
</div>
<!-- 
<div *ngIf='showPayments'class="aupris_main my-full-screen-dialog">
 
  <div class="text-center" style="font-size:20px;font-weight: bold;margin-bottom: 10px;">{{
    paymentVars?.Labels['VarNotice'] || 'Hinweis'}} 
  </div>
    <div>
      {{languageData.Popups?.VarWeb-PcPopups1 || 'Der Zahlungsdienstleister hat die ausgewählte Zahlungsmethode abgelehnt. Weitere Informationen zum Grund wurden uns nicht mitgeteilt.'}}
      <br> {{languageData.Popups?.VarWeb-PcPopups2 || 'Bitte wählen Sie eine andere Zahlungsmethode!'}}
    </div>
    <div class="aucol-xl-8 aucol-lg-7 aucol-md-6 aucol-sm-7 aucol-xs-9 smallscreenwidth p-0">
      <select class="auform-control infield" style="margin-left:90px; margin-top: 10px;"
        (change)="selectedPaymentOption($event.target.value)">
        <option *ngFor="let option of paymentOptions" [value]="option._id">
          {{option.name}}
        </option>
      </select>
    </div>
     <div class="text-right">
      <button class="au-btn btn-default"
        [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}" (click)="onclick()"
        style="margin-top:57px">{{'Confirm'}}</button>
    </div>
</div> -->


<div *ngIf='showPayments'class="aupris_main my-full-screen-dialog">
 
  <div class="text-center" style="font-size:20px;font-weight: bold;margin-bottom: 10px;">
    {{languageData.Popups?.VarWeb-PcPopups6 || "Finanzierung abgelehhnt"}}
  </div>
  <div [innerHTML]="languageData.Popups?.VarWeb-PcPopups7 || 
    'Leider wurde Ihre Finanzierungsanfrage durch unseren Finanzpartner abgelehnt.
    Gerne können Sie zu einem späteren Zeitpunkt eine neue Anfrage starten.<br>
    Alternativ können Sie auch sofort eine andere Zahlungsmethode auswählen und 
    Ihre Bestellung erneut durchführen.'">
  </div>
      
     <div class="text-center">
      <button class="au-btn btn-default"
        [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}" (click)="onclick()"
        style="margin-top:12px">{{languageData.Popups?.VarWeb-PcPopups8 || "Zum Preisrechner"}}</button>
    </div>
</div>