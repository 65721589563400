<!--*------------------------------------------  ------------------------------------------------------*-->

<div style="width: 99%;" *ngIf="!heatingOverview && setupView">
  <div class="meine1" *ngIf="select"
    style="margin-bottom: 15px;position: relative;top: 10px;color: var(--primaryTextColour)">
    <button class="btnalign" (click)="openAdress()">
      {{'Andere Heizung Auswählen'}}
    </button>
  </div>

  <div class="meine" (click)="meinepopupopen()">
    <svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" width="70" height="62" viewBox="0 0 20 20">
      <path id="ic_add_circle_outline_24px"
        d="M10.631,5.923H9.062V9.062H5.923v1.569H9.062V13.77h1.569V10.631H13.77V9.062H10.631ZM9.847,2a7.847,7.847,0,1,0,7.847,7.847A7.85,7.85,0,0,0,9.847,2Zm0,14.124a6.277,6.277,0,1,1,6.277-6.277A6.286,6.286,0,0,1,9.847,16.124Z"
        transform="translate(-2 -2)" fill="var(--primaryColour)" />
    </svg>
    <h1 style="margin-top:-4px; color:var(--primaryColour)">
      {{languageData['Meine_Heizung']?.VarKundeportalMeine_Heizung1||'Meine Heizung einrichten'}}
    </h1>
  </div>
</div>

<!--*---------------------------------------- Heating Setup Overview  ------------------------------------------------------------*-->

<div *ngIf="heatingOverview && !showAdresses">
  <div class="meine1" style="margin-bottom: 15px;position: relative;top: 10px;color: var(--primaryTextColour)">
    <button class="btnalign" (click)="openAdress()">
      {{'Andere Heizung Auswählen'}}
    </button>
  </div>
  <!-- <div class="container-fluid meine1 meine2">
    <div>
      <div class="col-md-4">
        <div class="position-relative" style="width: 240px; height: 240px; margin-top: -20px;">
          <span [innerHTML]="getPercenatgeLevel(selectAdress.myheating.activePercentageLevel)"></span>
        </div>

        <p class="percentage">
          {{selectAdress.myheating.activePercentageLevel}}%
        </p>
        <p class="quantity">
          {{selectAdress.myheating.currentActiveLevel | replaced}}
          {{selectAdress.myheating.productUnits.name}}
        </p>
        <p class="reichalign">
          Reichweite: {{selectAdress.myheating.remainingDays}} Tage
        </p>
        <p class="datealign">
          {{selectAdress.myheating.currentDate | date: 'dd.MM.yy'}}
        </p>
        <p class="quantityalign">
          Max {{selectAdress.myheating.maxTankSize | replaced }}
          {{selectAdress.myheating.productUnits.name}}
        </p>

        <div class="one">
          1
        </div>
        <div class="two">
          2
        </div>
      </div>

      <div class="col-md-8">
        <div class="svgalign">
          <span (click)="openFilling()" style="cursor: pointer;">
            <img style="position: relative;right: 12px;" src="assets/inventory/befullung.svg">
            <span style="position: relative;left: 4px;color: black;"> Befüllung</span>
          </span>
          <span style="cursor: pointer;" (click)="openBestands()">
            <img src="assets/inventory/Bestandskontrolle.svg">
            <span> Bestandskontrolle</span>
          </span>
          <span style="cursor: pointer;">
            <img style="position: relative;left: 10px;" src="assets/inventory/preisrechner.svg">
            <span style="position: relative;left: 4px;">Preisrechner</span>
          </span>
          <span style="cursor: pointer;">
            <img src="assets/inventory/Info.svg">
            <span style="position: relative;left: 35px;bottom: 5px;">Info</span>
          </span>
        </div>
      </div>
    </div>

    <div style="height: 90px;">
      <div class="addressDiv">
        <div>
          <b>
            {{selectAdress?.currentTitle}}
            {{selectAdress?.firstName}}
            {{selectAdress?.lastName}}
          </b>
        </div>
        <div>
          {{selectAdress?.companyName}}
          {{selectAdress?.addressZusatz}}
        </div>
        <div>
          {{selectAdress?.streetName}}
          {{selectAdress?.houseNo}}
        </div>
        <div>
          {{selectAdress?.zipCode}}
          {{selectAdress?.city}}
        </div>
      </div>
    </div>

    <div id="chartContainer" #chartContainer
      style="height: 275px !important;position: relative;top: 80px;right: 170px;width: 700px;" class="col-md-12">
    </div>
  </div> -->

  <div class="aupris_main scrolltotopprogress stage">
    <div class="au-container section-container card" style="padding: 18px 0px 0px 0px;margin-top: 0px !important;height: 370px;">
      <div class="aucol-lg-3 aucol-md-3 aucol-sm-4 aucol-xs-4" *ngIf="heatingSystemInfo" style="margin-top: -20px;">
        <div class="position-relative" style="height: 240px;">
          <span [innerHTML]="getPercentageLevel(selectAdress.myheating)"></span>
        </div>

        <p class="percentage">
          {{selectAdress.myheating.activePercentageLevel}}%
        </p>
        <p class="quantity">
          {{selectAdress.myheating.currentActiveLevel | replaced}}
          {{selectAdress.myheating.productUnits.name}}
        </p>
        <p class="reichalign">
          Reichweite: {{selectAdress.myheating.remainingDays}} Tage
        </p>
        <p class="datealign">
          {{selectAdress.myheating.currentDate | date: 'dd.MM.yy'}}
        </p>
        <p class="quantityalign">
          Max {{selectAdress.myheating.maxTankSize | replaced }}
          {{selectAdress.myheating.productUnits.name}}
        </p>

        <!-- <div class="one">
          1
        </div>
        <div class="two">
          2
        </div> -->

        <div class="svgalign">
          <span (click)="openFilling()" style="cursor: pointer;position: relative;left: 11px;">
            <span [innerHTML]="befullung"></span>
            <span style="position: relative;left: 15px;color: black;">
              Befüllung
            </span>
          </span>
          <span style="cursor: pointer;" (click)="openBestands()">
            <span [innerHTML]="Bestandskontrolle" style="position: relative;left: 11px;"></span>
            <span>
              Bestandskontrolle
            </span>
          </span>
          <span style="cursor: pointer;position: relative;left: 21px;">
            <span [innerHTML]="preisrechner"></span>
            <span >
              Preisrechner
            </span>
          </span>
          <span style="cursor: pointer;position: relative;left: 11px" (click)="editHeating()">
            <span [innerHTML]="Info"></span>
            <span style="position: relative;left: 34px;bottom: 5px;">
              Info
            </span>
          </span>
        </div>
      </div>

      <div class="aucol-lg-1 aucol-md-1">

      </div>

      <div class="aucol-lg-8 aucol-md-8 aucol-sm-8 aucol-xs-12 ">
        <div class="addressDiv">
          <div>
            <b>
              {{selectAdress?.currentTitle}}
              {{selectAdress?.firstName}}
              {{selectAdress?.lastName}}
            </b>
          </div>
          <div>
            {{selectAdress?.companyName}}
            {{selectAdress?.addressZusatz}}
          </div>
          <div>
            {{selectAdress?.streetName}}
            {{selectAdress?.houseNo}}
          </div>
          <div>
            {{selectAdress?.zipCode}}
            {{selectAdress?.city}}
          </div>
        </div>

        <div id="chartContainer" #chartContainer style="height: 275px !important;width: 700px;" class="col-md-12">
        </div>
      </div>
    </div>
  </div>

  <!-- Show bar graph Info section start here -->
  <div class="aupris_main scrolltotopprogress stage">
    <div class="au-container section-container card" style="padding: 18px 0px 10px 0px;">
      <div class="aucol-lg-3 aucol-md-3 aucol-sm-4 aucol-xs-4" *ngIf="heatingSystemInfo">

        <!-- <div class="aucol-md-6 aucol-lg-5" style="margin-top:10px;padding-left:0px">
          <img class="houseIcon" src="../../assets/inventory/Heaterrr.svg">
          <strong style="font-size: 16px;">
            {{languageData['Adressen']['VarKundeportalAdressen2']||"Energiebedarf"}}
          </strong>
        </div>

        <div class="aucol-md-6 aucol-lg-5" style="margin-top:5px">
          <div>{{heatingSystemInfo?.productName}} </div>
          <div>{{heatingSystemInfo?.heatingSystemName}}</div>
          <div>{{heatingSystemInfo?.houseName}} </div>
        </div> -->


        <!-- <strong style="font-size: 16px;">
          {{languageData['Adressen']['VarKundeportalAdressen2']||"Energiebedarf"}}
        </strong> -->

        <div class="aucol-lg-12 aucol-md-12 details"
          style="display: flex;padding-left: 0px;margin-top: 5px;justify-content: space-between;">
          <div>
            <!-- <img class="houseIcon" src="../../assets/inventory/Heaterrr.svg"> -->
            <span [innerHTML]="Heaterrr" class="houseIcon"></span>
            <strong style="font-size: 16px;">
              {{languageData['Adressen']['VarKundeportalAdressen2']||"Energiebedarf"}}
            </strong>
          </div>
          <div style="margin-top:5px">
            <div>{{heatingSystemInfo?.productName}} </div>
            <div>{{heatingSystemInfo?.heatingSystemName}}</div>
            <div>{{heatingSystemInfo?.houseName}} </div>
          </div>
        </div>


        <div class="aucol-lg-12 aucol-md-12 details" style="padding-left: 0px;margin-top: 5px;">
          <div>
            <label>
              Beheizte Fläche:
            </label>
            <span style="float: right;">
              {{heatingSystemInfo?.houseArea}}
              <span>
                m<sup>2</sup>
              </span>
            </span>
          </div>
          <div>
            <label>
              Innentemperatur:
            </label>
            <span style="float: right;">
              {{heatingSystemInfo?.roomTempeature}}
              <span>
                °C
              </span>
            </span>
          </div>
          <div>
            <label>
              Personen im Haushalt:
            </label>
            <span style="float: right;position: relative;right: 2px;">
              {{heatingSystemInfo?.familySize}}
              <span style="visibility: hidden;">
                °C
              </span>
            </span>
          </div>
          <div>
            <label>
              Sonnenkollektoren:
            </label>
            <span style="float: right;position: relative;right: 2px;">
              {{heatingSystemInfo?.solarPannelArea || 0}}
              <span style="visibility: hidden;">
                °C
              </span>
            </span>
          </div>
          <div>
            <label>
              Heizungsunterstützung:
            </label>
            <span style="float: right;position: relative;right: 2px;">
              {{heatingSystemInfo?.heatingSupport ? "Yes" : "No"}}
              <span style="visibility: hidden;">
                °C
              </span>
            </span>
          </div>
          <div>
            <label>
              Geschätzter Normverbrauch:
            </label>
            <span style="float: right;position: relative;left: 4px;">
              {{heatingSystemInfo?.grossConsumption | replaced}}
              <span>
                {{'kg/l'}}
              </span>
            </span>
          </div>
        </div>
      </div>

      <div class="aucol-lg-1 aucol-md-1">

      </div>

      <div class="aucol-lg-8 aucol-md-8 aucol-sm-8 aucol-xs-12 ">
        <!-- <div id="bar-chart-container" style="height: 275px !important"></div> -->
        <div id="bar-chart-container" style="height: 275px !important;width: 700px;" class="col-md-12">
        </div>
      </div>
    </div>
  </div>
  <!-- Show bar graph Info section End here -->

  <!-- tile 3 real consumption -->
  <div class="aupris_main scrolltotopprogress stage" *ngIf="false">
    <div class="au-container section-container card" style="display: flex;">
      <div class="aucol-lg-4 aucol-md-8 aucol-sm-4 aucol-xs-12">
        <div class="aucol-md-6" style="margin-top:10px;padding-left:0px">
          <img src="../../assets/inventory/Graph.svg" style="display: block;margin-left:25px;width: 60px;">
        </div>

        <div class="aucol-lg-12 aucol-md-12 details" style="padding: 0px;margin-top: 12px;" *ngIf="!addNewEntry">
          <strong style="font-size: 16px;">
            {{languageData['Energie']['VarKundeportalEnergie5'] || "Realverbrauch"}}
          </strong>
          <form>
            <ng-container>
              <div *ngFor="let item of realverbrauch; let i = index"
                style="display: flex; justify-content: space-between; width: 320px;">
                <div *ngIf="addNewEntry">
                  <label style="margin-right: 105px;">{{item?.year}}:</label>
                  {{item?.total}} kg
                </div>
                <div *ngIf="!addNewEntry">
                  <label style="margin-right: 105px;">{{item?.year}}:</label>
                  {{item?.total}} kg
                </div>
              </div>
            </ng-container>
          </form>
        </div>

        <div class="aucol-lg-12 aucol-md-12 details" style="padding: 0px;margin-top: 12px;" *ngIf="addNewEntry">
          <strong style="font-size: 16px;">
            {{languageData['Energie']['VarKundeportalEnergie5'] || "Realverbrauch"}}
          </strong>
          <form>

            <ng-container style="width: 50%;">
              <div *ngFor="let form of consumptionForms; let i = index" [formGroup]="form" class="consumption-row"
                style="margin-top: 12px;">
                <div>
                  <div class="input-d margin-bottom">
                    <input class="aucol-lg-2 auform-control" formControlName="year">
                    <span class="aucol-lg-1"></span>

                    <input class="aucol-lg-2 auform-control" formControlName="total">
                    <span class="aucol-lg-1" style="padding: 7px 0px 0px 4px">KG</span>
                  </div>
                  <div class="input-error"
                    *ngIf="form.controls.year.dirty && form.controls.year.invalid && form.controls.year.pristine">
                    invalid year
                  </div>
                </div>
              </div>
              <div *ngIf="errorMessage" class="error-message aucol-lg-12" style="padding: 0px;">
                {{ errorMessage }}
              </div>
              <div class="aucol-md-12 aucol-lg-12" style="padding: 0px;margin-top: 22px;">
                <button class="au-btn cancel left" (click)="onCancel()">Abbrechen</button>
                <button class="au-btn" (click)="onSave()" style="margin-left: 12px;">Speichern</button>
              </div>
            </ng-container>
          </form>
        </div>
        <div style="display: flex;justify-content: start; padding-left: 0px;margin-left: 0px;margin-top: 12px;"
          class="addNewEntry aucol-lg-12 aucol-md-12" (click)="addConsumption()" *ngIf="!addNewEntry">
          <img src="../../assets/inventory/add-outline.svg" style="display: block;margin-top:6px;width: 30px;">
          <span class="content" style="padding-left: 5px;">
            {{languageData['Energie']['VarKundeportalEnergie6'] || "Verbräuche hinzufügen oder bearbeiten"}}
          </span>
        </div>
      </div>
      <div class="aucol-lg-8 aucol-md-8 aucol-sm-8 aucol-xs-12 ">
        <div id="realConsumption-chart-container" style="height: 295px !important;"></div>
      </div>
    </div>
  </div>
</div>
<loader [full-screen]="true" *ngIf="showFullScreenLoading" [color]="'var(--primaryColour)'"></loader>
