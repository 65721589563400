import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InventoyTrackingService {
  baseUrl: string = environment.baseUrl;
  public notifyHeatingRecord = new Subject<any>();
  notifyHeatingRecordObservable$ = this.notifyHeatingRecord.asObservable();
  constructor(protected http: HttpClient) { }

  saveWeatherId(weatherObj): Observable<any> {
    return this.http.post(`${this.baseUrl}/inventory/saveWeatherId`, weatherObj)
  }

  fetchInventoryProducts(weatherObj): Observable<any> {
    return this.http.get(`${this.baseUrl}/inventory/products`)
  }
  getMyHeating(): Observable<any> {
    return this.http.get(`${this.baseUrl}/inventory/myHeating`)
  }
  getbarGraphData(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/inventory/barGraphApi`, data)
  }
  getLineGraphData(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/inventory/lineGraph`, data)
  }

  saveIventoryFilling(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/inventory/saveFilling`, data)
  }

  emitNewHeatingReocrd(data) {
    this.notifyHeatingRecord.next(data);
  }

}
