import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThirdPartyService {
  apiKey = "35658a6cc2474bd6a8933107242302";
  baseURL = "https://api.weatherapi.com/v1";
  projectURL: string = environment.baseUrl;
  constructor(protected http: HttpClient) { }

  searchAddressId(city: string): Observable<any> {
    return this.http.get(`${this.baseURL}/search.json?key=${this.apiKey}&q=${city}`);
  }

  getNearWeatherStation(meteostatPayLoad: any): Observable<any> {
    // return this.http.get(`${this.projectURL}/weather/nearWeatherStation`, {
    //   params: {
    //     lat: meteostat.lat,
    //     lng: meteostat.lng,
    //     city: meteostat.city,
    //     suburb: meteostat.suburb,
    //     zipCode: meteostat.zipCode,
    //     country: meteostat.country,
    //     _id: meteostat._id,
    //     plzId: meteostat.plzId,
    //   }
    // })
    return this.http.post(`${this.projectURL}/weather/nearWeatherStation`, meteostatPayLoad)
  }
}
