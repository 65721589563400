import { Component, Inject, OnInit } from '@angular/core';
import { AdminService } from 'app/Services/admin.service';
import { InventoyTrackingService } from 'app/Services/inventoy-tracking.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-addresspopup',
  templateUrl: './addresspopup.component.html',
  styleUrls: ['./addresspopup.component.css']
})
export class AddresspopupComponent implements OnInit {


  constructor(public adminService: AdminService,
    private inventoryService: InventoyTrackingService,
    private dialogRef: MatDialogRef<AddresspopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
   }

  ngOnInit(): void {
  }


    natigateSetup(index){
      this.dialogRef.close({index})
    }
  }
