import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { invalid } from 'moment-timezone';

@Component({
  selector: 'app-cosumption',
  templateUrl: './cosumption.component.html',
  styleUrls: ['./cosumption.component.css']
})
export class CosumptionComponent implements OnInit {
  public errorMessage: string;
  consumptionForms: FormGroup[] = [];
  constructor(public dialogRef: MatDialogRef<CosumptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[], private fb: FormBuilder) { }

  ngOnInit(): void {
    console.log(this.data, 18)
    this.data.push({
      "year": '',
      "total": ''
    });
    // this.data.forEach(consumption => {
    //   if (consumption.year !== undefined && consumption.total !== undefined) {
    //     this.consumptionForms.push(this.fb.group({
    //       year: [[consumption.year], [Validators.required, Validators.min(1970), Validators.max(new Date().getFullYear() - 1), Validators.pattern('^[0-9]*$')]],
    //       total: [consumption.total, [Validators.required, Validators.pattern('^[0-9]*$')]]
    //     }));
    //   }
    // });
    this.data.forEach(consumption => {
      if (consumption.year !== undefined && consumption.total !== undefined) {
        const formGroup = this.fb.group({
          year: [[consumption.year], [Validators.required, Validators.min(1970), Validators.max(new Date().getFullYear() - 1), Validators.pattern('^[0-9]*$')]],
          total: [consumption.total, [Validators.required, Validators.pattern('^[0-9]*$')]]
        });
        this.consumptionForms.push(formGroup);

        formGroup.statusChanges.subscribe(status => {
          if (status === 'INVALID') {
            this.errorMessage = 'Nur positive und ganzzahlige Eingaben akzeptiert';
          } else {
            this.errorMessage = null;
          }
        });
      }
    });
  }
  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    const emptyForms = this.consumptionForms.filter(form => form.value.year[0] === '' || form.value.total === '');
    if (emptyForms.length > 0) {
      this.errorMessage = 'Bitte machen Sie eine gültige Jahres- und Verbrauchseingabe!';
      return;
    }
    const inValidForms = this.consumptionForms.filter(form => form.invalid);
    if (inValidForms.length > 0) {
      return
    }
    const values = this.consumptionForms.map(form => ({ "year": form.value.year[0], "total": parseInt(form.value.total) }));
    let filteredData = values.filter(value => value.year !== undefined && value.total !== undefined && value.year !== '');
    console.log(filteredData);
    this.dialogRef.close(filteredData);
  }
}
